import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequestSharedService {

  constructor() {}

  private subjectOpenApprovalHistoryLevelModal = new BehaviorSubject(null)
  private subjectRequestListChanged = new BehaviorSubject(null)


  setSubjectOpenWorkflowRequestModal(id: any) {
    this.subjectOpenApprovalHistoryLevelModal.next(id);
  }

  getSubjectOpenWorkflowRequestModal(): Observable<any> {
    return this.subjectOpenApprovalHistoryLevelModal.asObservable();
  }

  setSubjectRequestListChanged(flag:boolean){
    this.subjectRequestListChanged.next(flag);
  }

  getSubjectRequestListChanged(): Observable<any> {
    return this.subjectRequestListChanged.asObservable();
  }
}
