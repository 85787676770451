import { Injectable } from '@angular/core';
import { BaseDataService } from './base-data.service';
import { Observable } from 'rxjs';
import { NotificationList } from '../models/notification.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationsDataService {

  private apiNotificationUrl = 'api/Notification';
  constructor(private baseDataService : BaseDataService) { }

  getNotificationsList(isUnreadOnly:boolean=false): Observable<NotificationList[]> {
    return this.baseDataService.makePostCall(`${this.apiNotificationUrl}/getAllNotifications?isUnreadOnly=${isUnreadOnly}`,{})
  }
  markAllAsRead(): Observable<boolean> {
    return this.baseDataService.makePutCall(`${this.apiNotificationUrl}/markAllAsRead`,{})
  }
  markAsReadById(id: string): Observable<boolean> {
    return this.baseDataService.makePutCall(`${this.apiNotificationUrl}/markAsRead?notificationId=${id}`,{})
  }
  getUnreadCount(): Observable<any> {
    return this.baseDataService.makeGetCall(`${this.apiNotificationUrl}/getUnreadCount`)
  }



}
