<ng-template #template>
    <div class="rest-icon">

    </div>

    <div class="row">
        <h6 class=" pull-left filter-modal-title col-md-4"> <b>{{'FILTER_MENU.FILTER_BY' | translate}}</b> </h6>
        <div class="col-md-2 modal-title text-end">
            <a (click)="addNewFilterValue()" style="cursor: pointer;"
                *ngIf="getFilterValues?.controls?.length<=coloumnsData.length"><span
                    class="text-success material-icons-outlined filter-btn-icon icon-font-size"><mat-icon>add</mat-icon></span></a>

            <a style="cursor: pointer;" (click)="resetFilter()"> <span class="text-end"><i
                        class="material-icons-outlined filter-btn-icon icon-font-size">restart_alt</i></span></a>
        </div>
    </div>
    <!-- <div class="modal-header">
           <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
              <span aria-hidden="true">×</span>
           </button>
        </div> -->
    <div class="modal-body p-2">

        <div class="col-12 mt-3 filter-container">
            <form [formGroup]="filterForm" class="filter-form">



                <!-- <mat-form-field class=" search-input mt-2 full-width-field" appearance="">
                    <mat-label>Search</mat-label>
                    <input matInput (input)="searchChange($event)" />
                    <mat-icon matSuffix class="face-icon">search</mat-icon>
                </mat-form-field> -->
                <!-- <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" formControlName="columns">
              <mat-radio-button class="example-radio-button" *ngFor="let item of coloumnsData" [value]="item.data">
                {{item.title}}
              </mat-radio-button>
            </mat-radio-group> -->
                <div formArrayName="Filters">

                    <div *ngFor="let listItem of getFilterValues?.controls; let i=index" [formGroupName]="i">

                        <div class="row mt-3">
                            <div class="col-md-9 filter-card-col">
                                <mat-accordion class="example-headers-align " multi>
                                    <mat-expansion-panel disabled="{{!listItem.get('IsActive').value}}">

                                        <mat-expansion-panel-header [collapsedHeight]="'130px'">
                                            <div class="display-header">
                                                <mat-slide-toggle formControlName="IsActive"></mat-slide-toggle>
                                                <span class="text-success" style="cursor: pointer;"
                                                    (click)="addNewFilterValue()"
                                                    *ngIf=" (getFilterValues?.controls?.length<=coloumnsData.length)"><mat-icon>add</mat-icon></span>
                                                <span class="text-danger" style="cursor: pointer;"
                                                    (click)="removeFilterValue(i)"><mat-icon>close</mat-icon></span>
                                            </div>
                                            <ul class="mt-3">
                                                <li><b>
                                                    {{'FILTER_MENU.FILTER_BY' | translate}} :
                                                    </b>
                                                    {{getFilterValues?.controls[i]?.get("Translate").value | translate}}
                                                </li>
                                                <li>
                                                    <b>
                                                        {{'FILTER_MENU.CONDITION' | translate}} :
                                                    </b>
                                                    {{getFilterValues?.controls[i]?.get("ConditionText").value}}
                                                </li>
                                                <li><b>{{'FILTER_MENU.VALUE' | translate}} : </b>
                                                    {{ listItem.get('DataType').value=='LOOKUP' &&
                                                    allowLookup.includes(listItem.get('Condition').value) ?
                                                    joinValue : listItem.get('DataType').value=='DATE' ? (getFilterValues?.controls[i]?.get("Value").value | date: 'dd-MM-yyyy')
                                                    : getFilterValues?.controls[i]?.get("Value").value}}

                                                </li>
                                            </ul>
                                            <!-- <mat-icon>account_circle</mat-icon> -->
                                            <!-- <mat-panel-description>

                                          
                                            </mat-panel-description> -->
                                        </mat-expansion-panel-header>
                                        <div class="{{!listItem.get('IsActive').value ? 'disabled-card' : ''}} ">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-3">


                                                    </div>
                                                    <div class="col-md-9">
                                                        <h5 class="card-title text-end">


                                                        </h5>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-12" *ngIf="i>0">
                                                        <mat-form-field class="example-full-width form-input-field">
                                                            <mat-label></mat-label>
                                                            <mat-select formControlName="FilterClause" class="disabled"
                                                                (selectionChange)="filterByChange($event,i)">
                                                                <!-- <mat-option>
                                                        <ngx-mat-select-search placeholderLabel="{{'MASTER_MENU.SEARCH' | translate}}" noEntriesFoundLabel="{{'MASTER_MENU.NO_RESULTS_FOUND' | translate}}" [(ngModel)]="filterBySearch" [ngModelOptions]="{standalone: true}"  ></ngx-mat-select-search>
                                                      </mat-option> -->
                                                                <mat-option class="form-select-option"
                                                                    *ngFor="let clause of listItem.get('FilterClauseArray').value "
                                                                    [value]="clause?.id">
                                                                    {{clause?.name}}</mat-option>
                                                            </mat-select>

                                                        </mat-form-field>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <mat-form-field class="example-full-width form-input-field">
                                                            <mat-label> {{'FILTER_MENU.FILTER_BY' | translate}}</mat-label>
                                                            <mat-select formControlName="FilterBy" class="disabled"
                                                                (selectionChange)="filterByChange($event,i)">
                                                                <!-- <mat-option>
                                                        <ngx-mat-select-search placeholderLabel="{{'MASTER_MENU.SEARCH' | translate}}" noEntriesFoundLabel="{{'MASTER_MENU.NO_RESULTS_FOUND' | translate}}" [(ngModel)]="filterBySearch" [ngModelOptions]="{standalone: true}"  ></ngx-mat-select-search>
                                                      </mat-option> -->
                                                                <mat-option class="form-select-option"
                                                                    *ngFor="let coloumn of listItem.get('ColoumnsArray').value "
                                                                    [value]="coloumn?.data">
                                                                    {{coloumn?.translate | translate}}</mat-option>
                                                            </mat-select>

                                                        </mat-form-field>
                                                    </div>


                                                    <div class="col-md-12">
                                                        <mat-form-field class="example-full-width form-input-field ">
                                                            <mat-label> {{'FILTER_MENU.CONDITION' | translate}}</mat-label>
                                                            <mat-select formControlName="Condition"
                                                                (selectionChange)="conditionChange($event,i)">
                                                                <!-- <mat-option>
                                                        <ngx-mat-select-search placeholderLabel="{{'MASTER_MENU.SEARCH' | translate}}" noEntriesFoundLabel="{{'MASTER_MENU.NO_RESULTS_FOUND' | translate}}" [(ngModel)]="filterBySearch" [ngModelOptions]="{standalone: true}"  ></ngx-mat-select-search>
                                                      </mat-option> -->
                                                                <mat-option class="form-select-option"
                                                                    *ngFor="let condition of (listItem.get('ConditionArray').value) "
                                                                    [value]="condition?.id">
                                                                    {{condition?.name}}</mat-option>
                                                            </mat-select>

                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <mat-form-field
                                                            class="example-full-width form-input-field {{((listItem.get('DataType').value=='LOOKUP' || listItem.get('DataType').value=='NUMBERLOOKUP')  && allowLookup.includes(listItem.get('Condition').value) || listItem.get('DataType').value=='DATE' ) ? 'd-none':''}} ">
                                                            <mat-label>
                                                                {{listItem.get('Condition').value=='Between' ? this.fromLabel
                                                                :this.valueLabel}}
                                                                <!-- <span *ngIf="listItem.get('Condition').value=='Between'">
                                                                From
                                                            </span>   
                                                            
                                                            <span *ngIf="listItem.get('Condition').value=='' || !listItem.get('Condition').value=='Between'">
                                                                Value
                                                            </span>    -->



                                                            </mat-label>

                                                            <input type="text" formControlName="Value" matInput
                                                                (keyup)="onKeyUp($event,i)" class="">



                                                            <!-- <mat-error *ngIf="vehicleAssignmentForm.get('Note').hasError('required')">Additional Vehicle Note is required
                                                    </mat-error> -->

                                                        </mat-form-field>
                                                    </div>
                                                    <div
                                                        class="col-md-12 {{(listItem.get('DataType').value=='LOOKUP' || listItem.get('DataType').value=='NUMBERLOOKUP')  && allowLookup.includes(listItem.get('Condition').value) ? '':'d-none'}}">
                                                        <mat-form-field class="example-full-width form-input-field">
                                                            <mat-label>
                                                                {{listItem.get('Condition').value=='Between' ? 'From'
                                                                :'Value'}}
                                                                <!-- <span *ngIf="listItem.get('Condition').value=='Between'">
                                                                From
                                                            </span>   
                                                            
                                                            <span *ngIf="listItem.get('Condition').value=='' || !listItem.get('Condition').value=='Between'">
                                                                Value
                                                            </span>    -->



                                                            </mat-label>

                                                            <mat-select formControlName="LookupValue" class="" multiple
                                                                (selectionChange)="getlookupvalue(i)">

                                                                <mat-option class="form-select-option"
                                                                    *ngFor="let condition of (listItem.get('LookupArray').value) "
                                                                    [value]="condition?.id">
                                                                    {{condition?.name}}</mat-option>

                                                            </mat-select>


                                                            <!-- <mat-error *ngIf="vehicleAssignmentForm.get('Note').hasError('required')">Additional Vehicle Note is required
                                                    </mat-error> -->

                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-md-12"
                                                        *ngIf="listItem.get('Condition').value=='Between' && listItem.get('DataType').value!='DATE'">
                                                        <mat-form-field class="example-full-width form-input-field">
                                                            <mat-label> To</mat-label>
                                                            <input type="text" formControlName="SecondaryValue" matInput
                                                                (keyup)="onKeyUp($event,i)">
                                                            <!-- <mat-error *ngIf="vehicleAssignmentForm.get('Note').hasError('required')">Additional Vehicle Note is required
                                                    </mat-error> -->

                                                        </mat-form-field>
                                                    </div>

                                                    <div
                                                        class="col-md-12 {{listItem.get('DataType').value=='DATE'?'':'d-none'}}">
                                                        <mat-form-field appearance="fill"
                                                            class="example-full-width form-input-field">
                                                            <input readonly matInput [matDatepicker]="picker"
                                                                formControlName="Value"
                                                                placeholder=" {{'FILTER_MENU.CHOOSE_A_DATE' | translate}}"
                                                                (dateChange)="addDate($event,i,true)">
                                                            <mat-datepicker-toggle matSuffix
                                                                [for]="picker"></mat-datepicker-toggle>
                                                            <mat-datepicker #picker></mat-datepicker>
                                                        </mat-form-field>
                                                        <mat-form-field appearance="fill"
                                                            class="example-full-width form-input-field"   *ngIf="listItem.get('Condition').value=='Between' && listItem.get('DataType').value=='DATE'">
                                                            <input readonly matInput [matDatepicker]="picker"
                                                                placeholder="Choose a date" formControlName="SecondaryValue"
                                                                (dateChange)="addDate($event,i,false)">
                                                            <mat-datepicker-toggle matSuffix
                                                                [for]="picker"></mat-datepicker-toggle>
                                                            <mat-datepicker #picker></mat-datepicker>
                                                        </mat-form-field>
                                                        <div *ngIf="listItem.get('Condition').value=='IsAnyOf' && listItem.get('DataType').value=='DATE'">

                                                            <span class="badge col-green" 
                                                            *ngFor="let date of this.getFilterValues.controls[i]?.get('Dates')?.value; let idx = index"
                                                            (click)="removeDate(idx,i)">
                                                            {{ date.value | date: 'dd-MM-yyyy' }}
                                                            <mat-icon>cancel</mat-icon>
                                                        </span>
                                                    </div>
                                                        <mat-chip-list>


                                                            <!-- <mat-chip
                                                              >
                                                               
                                                            </mat-chip> -->
                                                        </mat-chip-list>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                    </mat-expansion-panel>



                                </mat-accordion>
                            </div>
                        </div>



                    </div>
                </div>


            </form>

        </div>
    </div>
    <hr />
    <div class="filter-close-div">
        <button mat-flat-button color="primary" class="flat-info-btn btn-text-size" (click)="applyFilter()">
            {{'FILTER_MENU.APPLY_FILTER' | translate}}<mat-icon>add</mat-icon>
        </button>&nbsp;&nbsp;
        <button type="button" mat-stroked-button color="primary" class="btn-border outline-info-btn btn-text-size" (click)="modalRef.hide()">{{'FILTER_MENU.CLOSE' | translate}}</button>&nbsp;&nbsp;
    </div>
</ng-template>