<ng-template #template>
  <div class="rest-icon">
    <a style="cursor: pointer;" (click)="resetFilter()"> <span class="text-end"><i
          class="material-icons-outlined filter-btn-icon icon-font-size">restart_alt</i></span></a>
  </div>
    <h6 class="modal-title pull-left filter-modal-title">{{'FILTER_MENU.GROUP_BY' | translate}}</h6>
    <!-- <div class="modal-header">
         <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">×</span>
         </button>
      </div> -->
    <div class="modal-body p-2">
  
      <div class="col-12  filter-container">
        <form [formGroup]="filterForm" class="filter-form">
  
  
  
  
          <mat-form-field class=" search-input mt-2 full-width-field" appearance="">
            <mat-label>{{'FILTER_MENU.SEARCH' | translate}}</mat-label>
            <input matInput  (input)="searchChange($event)" />
            <mat-icon matSuffix class="face-icon">search</mat-icon>
          </mat-form-field>
          <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" formControlName="groupBy">
            <mat-radio-button class="example-radio-button " *ngFor="let item of coloumnsData" [value]="item.data">
              {{item?.translate | translate}}
            </mat-radio-button>
          </mat-radio-group>
  
  
        </form>
  
      </div>
    </div>
    <hr/>
    <div class="filter-close-div">
      <button mat-flat-button color="primary" class="flat-info-btn btn-text-size" (click)="applyFilter()">
        {{'FILTER_MENU.APPLY_FILTER' | translate}}<mat-icon>add</mat-icon>
    </button>&nbsp;&nbsp;
    <button type="button" mat-stroked-button color="primary" class="btn-border outline-info-btn btn-text-size" (click)="modalRef.hide()">{{'FILTER_MENU.CLOSE' | translate}}</button>&nbsp;&nbsp;
    </div>
  </ng-template>
  