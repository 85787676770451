import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from 'src/app/core/service/base-data.service';
import { CompanySettings, CodeSetup, CompanySettingsPaginated, CompanySettingsGroupedList } from '../models/company-settings.model';
import { BaseFilterVm } from 'src/app/shared/models/filter-data.model';
import { LookUpElement } from 'src/app/shared/models/common-data.model';
import { LookupImageModel } from 'src/app/home/master/driver-type-class-assign/models/driver-type-class-assign.model';
@Injectable({
  providedIn: 'root'
})
export class CompanySettingsService {

  private apiCompanySettingUrl="api/CompanySetting"
  private apiManageCompanySettingsUrl = 'api/CompanySetting/getAllCompanySettings'
  private apiDocumentCodeSetupUrl = 'api/DocumentCodeSetup'
  constructor(private baseDataService : BaseDataService) { }

  getCompanySettingList(): Observable<CompanySettings[]> {
    return this.baseDataService.makeGetCall(`${this.apiCompanySettingUrl}`)
  }
  getCompanyLookupList(): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apiCompanySettingUrl}/lookup`)
  }

  createCompanySetting(companySetting : CompanySettings): Observable<any> {
    return this.baseDataService.makePostCall(`${this.apiCompanySettingUrl}`,companySetting)
  }

  editCompanySetting(companySetting : CompanySettings): Observable<any> {
    return this.baseDataService.makePutCall(`${this.apiCompanySettingUrl}`,companySetting)
  }

  deleteCompanySetting(companySettingId : string): Observable<any> {
    return this.baseDataService.makeDeleteCall(`${this.apiCompanySettingUrl}/${companySettingId }`)
  }
  
  getCompanySetting(id:string) : Observable <CompanySettings>{
    return this.baseDataService.makeGetCall(`${this.apiCompanySettingUrl}/${id}`)
  }

  getAllCompanySettingsList(baseFilterVm: BaseFilterVm) {
    return this.baseDataService.makePostCall(`${this.apiManageCompanySettingsUrl}`,baseFilterVm)
  }

  getAllComapnySettings(baseFilterVm: BaseFilterVm): Observable<CompanySettingsPaginated | CompanySettingsGroupedList[]> {
    return this.baseDataService.makePostCall(`${this.apiCompanySettingUrl}/getAllCompanySettings`,baseFilterVm)
  }

  // document code setup

  createDocumentCodeSetup(codeSetup : CodeSetup): Observable<any> {
    return this.baseDataService.makePostCall(`${this.apiDocumentCodeSetupUrl}`,codeSetup)
  }

  editDocumentCodeSetup(codeSetup : CodeSetup[]): Observable<any> {
    return this.baseDataService.makePutCall(`${this.apiDocumentCodeSetupUrl}`,codeSetup)
  }

  deleteDocumentCodeSetup(codeSetupId : string): Observable<any> {
    return this.baseDataService.makeDeleteCall(`${this.apiDocumentCodeSetupUrl}/${codeSetupId }`)
  }
  
  getDocumentCodeSetup(id:string) : Observable <CodeSetup[]>{
    return this.baseDataService.makeGetCall(`${this.apiDocumentCodeSetupUrl}/getDocumentCodeSetupCompanySettingId?companySettingId=${id}`)
  }
  getuserCompanylookUp(isFleetAdmin:boolean) : Observable <LookupImageModel[]>{
    return this.baseDataService.makeGetCall(`${this.apiCompanySettingUrl}/userCompanylookUp?isFleetAdmin=${isFleetAdmin}`)
  }
}
