import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { LanguageService } from 'src/app/core/service/language.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import * as moment from 'moment';
import { MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { FileUploadData } from 'src/app/shared/models/file-upload.model';
import { FileUploadService } from "../../services/file-upload.service";
import { DocumentUpload } from '../../models/document-upload.model';
import { DocumentUploadSharedService } from '../../services/document-upload-shared.service';
import { Toast } from '../../data/constants';
@Component({
  selector: 'app-common-document-upload',
  templateUrl: './common-document-upload.component.html',
  styleUrls: ['./common-document-upload.component.scss']
})
export class CommonDocumentUploadComponent {
  @Input() categoryList = [];
  @Input() moduleType = "";
  @Input() attachmentId = ""
  documentAttachmentForm: FormGroup;
  currentUrl: string = "";
  attachmentList: DocumentUpload[] = [
    // {
    //   documentUrl: "",
    //   documentName: "",
    //   description: "",
    //   category: "",
    //   module: "",
    //   referenceId: "",
    //   id: ""
    // }
  ]
  attachmentObj: DocumentUpload
  isFileUploading : boolean = false;
  categorySearch: string =''
  displayedColumns: string[] = ['documentName','category','attachmentDescription','link','status','action'];
  dataSource = new MatTableDataSource<DocumentUpload>(this.attachmentList);
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  addOnBlur = true;
  documentAttachmentDataSubject: Subscription;
  isEditMode: boolean = false;
  isNewAtt: boolean = true;
  editIndex: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private translateService: TranslateService,
    private languageService: LanguageService,
    private helperService: HelpersService,
    private router: Router,
    private fb: FormBuilder,
    private paginatorIntl: MatPaginatorIntl,
    private dateAdapter: DateAdapter<Date>,
    private fileUploadService: FileUploadService,
    private documentUploadSharedService: DocumentUploadSharedService
  ) {
    this.documentAttachmentDataSubject = documentUploadSharedService
    .getsubjectDocumentAttachmentList()
    .pipe(takeUntil(this.destroy))
    .subscribe((list: DocumentUpload[]) => {
      if (list != null) {
        // console.log(list)
        this.attachmentList = list;
        this.dataSource = new MatTableDataSource<DocumentUpload>(this.attachmentList);
        this.dataSource.paginator = this.paginator;
      }
    });
    this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.languageService.getLanguageChangedSubject().pipe(takeUntil(this.destroy))
    .subscribe((flag: string) => {
      if (flag!=null) {
        this.setTranslations();
      }
    });
  }
  ngOnInit(): void {
    this.createAddVehicleForm();
    this.setTranslations();
  }

  createAddVehicleForm() {
    this.documentAttachmentForm = this.fb.group({
      AttachmentDescription: [''],
      AttachmentName: [''],
      AttachmentCategory: [''],
      Status: true
    })
  }

  setTranslations() {
    this.translateService
      .get([
        'MASTER_MENU.ITEMS_PER_PAGE'
      ])
      .subscribe((translations) => {
        this.paginatorIntl.itemsPerPageLabel = translations['MASTER_MENU.ITEMS_PER_PAGE'];
      });
  }

  onDocumentFileSelected(event: any , inputNo: number){
    if (inputNo === 2) {
      var blob=event.target.files[0];
      let formData: FormData = new FormData();
      formData.append("files", blob, blob.name);
      this.fileUploadService
        .uploadFile(formData)
        .pipe(takeUntil(this.destroy))
        .subscribe((res:FileUploadData) => {


          this.currentUrl = res.imageUrl;
          this.isFileUploading = false;
        });
    }
  }

  deleteImg() {
    this.currentUrl = "";
    this.isFileUploading = false;
  }

  getCategory(category: string) {
    let categoryName = ''
    if (category != '') {
      this.categoryList.forEach(element => {
        if (element.value == category) {
          categoryName = element.name;
        }
      });
    }
    return categoryName;
  }

  addAttachment() {
    this.attachmentObj = {
      documentName: this.documentAttachmentForm.get('AttachmentName')?.value,
      documentUrl: this.currentUrl,
      description: this.documentAttachmentForm.get('AttachmentDescription')?.value,
      category: this.documentAttachmentForm.get('AttachmentCategory')?.value,
      module: this.moduleType,
      referenceId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      isActive: this.documentAttachmentForm.get('Status')?.value,
    }
    console.log(this.attachmentObj, "this.attachmentObj");
    // if (this.attachmentId) {
    //   this.attachmentObj.id = this.attachmentId
    // }
    this.attachmentList.push(this.attachmentObj);
    console.log(this.attachmentList, this.moduleType+" "+"common")
    this.documentUploadSharedService.setsubjectDocumentAttachmentList(this.attachmentList)
    Toast.fire({
      icon: 'success',
      title: this.translateService.instant('MANAGE_ATTACHMENT.ATTACHMENT_RECORD_ADDED_SUCCESSFULLY'),
    });
    this.dataSource = new MatTableDataSource<DocumentUpload>(this.attachmentList);
    this.dataSource.paginator = this.paginator;
    this.documentAttachmentForm.get('AttachmentName').setValue('')
    this.documentAttachmentForm.get('AttachmentCategory').setValue('')
    this.documentAttachmentForm.get('AttachmentDescription').setValue('')
    this.documentAttachmentForm.get('Status').setValue(true);
    this.currentUrl = "";
  }
  editAttachment() {
    let attachmentListCopy = this.attachmentList;
    attachmentListCopy[this.editIndex].documentName = this.documentAttachmentForm.get('AttachmentName')?.value;
    attachmentListCopy[this.editIndex].category = this.documentAttachmentForm.get('AttachmentCategory')?.value;
    attachmentListCopy[this.editIndex].description = this.documentAttachmentForm.get('AttachmentDescription')?.value;
    attachmentListCopy[this.editIndex].isActive = this.documentAttachmentForm.get('Status')?.value;
    attachmentListCopy[this.editIndex].documentUrl = this.currentUrl;
    this.attachmentList = attachmentListCopy;
    this.documentUploadSharedService.setsubjectDocumentAttachmentList(this.attachmentList)
    Toast.fire({
      icon: 'success',
      title: this.translateService.instant('MANAGE_ATTACHMENT.ATTACHMENT_RECORD_SUCCESSFULLY_UPDATED'),
    });
    this.dataSource = new MatTableDataSource<DocumentUpload>(this.attachmentList);
    this.dataSource.paginator = this.paginator;
    this.clearEdit();
  }
  editRecord(indexNo: number) {
    this.editIndex = indexNo;
    this.isEditMode = true;
    this.isNewAtt = this.attachmentList[indexNo]?.id ? false : true;
    let element = document.getElementById("document-container");
    element.scrollTop = 0;
    this.documentAttachmentForm = this.fb.group({
      AttachmentDescription: [this.attachmentList[indexNo]?.description],
      AttachmentName: [this.attachmentList[indexNo]?.documentName],
      AttachmentCategory: [this.attachmentList[indexNo]?.category],
      Status: [this.attachmentList[indexNo]?.isActive]
    })
    this.currentUrl = this.attachmentList[indexNo]?.documentUrl;
  }

  removeAttachment(index: number) {
    this.attachmentList.splice(index,1);
    Toast.fire({
      icon: 'success',
      title: this.translateService.instant('MANAGE_ATTACHMENT.ATTACHMENT_RECORD_SUCCESSFULLY_REMOVED'),
    });
    this.dataSource = new MatTableDataSource<DocumentUpload>(this.attachmentList);
    this.dataSource.paginator = this.paginator;
  }

  clearEdit() {
    this.isEditMode = false;
    this.isNewAtt = true;
    let element = document.getElementById("document-container");
    element.scrollTop = 0;
    this.documentAttachmentForm.reset();
    this.documentAttachmentForm.get('Status').setValue(true);
    this.currentUrl = '';
  }

  ngOnDestroy(){
    this.attachmentList = [];
    this.documentUploadSharedService.setsubjectDocumentAttachmentList(null)
  }

}
