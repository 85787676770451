import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApprovalWorkflowListSharedService {

  constructor() {}
  private subjectOpenReviewApplicationModal = new BehaviorSubject(null);
  private subjectSelectTaskHistoryId = new BehaviorSubject(null);
  private subjectWorkflowListTableChanged = new BehaviorSubject(null);
  private subjectOpenWorkflowRequestModal = new BehaviorSubject(null);
  private subjectTaskHistoryListTableChanged = new BehaviorSubject(null);
  private subjectScrollToTaskHistory = new BehaviorSubject(null);
  private subjectNumberOfCompletedTask = new BehaviorSubject(null);

  setsubjectOpenReviewApplicationModal(approveObj: any) {
    this.subjectOpenReviewApplicationModal.next(approveObj);
  }

  getsubjectOpenReviewApplicationAddModal(): Observable<any> {
    return this.subjectOpenReviewApplicationModal.asObservable();
  }

  setSubjectOpenWorkflowRequestModal(approveObj: any) {
    this.subjectOpenWorkflowRequestModal.next(approveObj);
  }

  getSubjectOpenWorkflowRequestModal(): Observable<any> {
    return this.subjectOpenWorkflowRequestModal.asObservable();
  }

  setsubjectSelectTaskHistoryId(string: string) {
    this.subjectSelectTaskHistoryId.next(string);
  }

  getsubjectSelectTaskHistoryId(): Observable<any> {
    return this.subjectSelectTaskHistoryId.asObservable();
  }

  getsubjectWorkflowListTableChanged(): Observable<any> {
    return this.subjectWorkflowListTableChanged.asObservable();
  }

  setsubjectWorkflowListTableChanged(flag: boolean) {
    this.subjectWorkflowListTableChanged.next(flag);
  }

  setSubjectTaskHistoryListTableChanged(flag: boolean) {
    this.subjectTaskHistoryListTableChanged.next(flag);
  }

  getSubjectTaskHistoryListTableChanged(): Observable<any> {
    return this.subjectTaskHistoryListTableChanged.asObservable();
  }

  setSubjectScrollToTaskHistory(string: string) {
    this.subjectScrollToTaskHistory.next(string);
  }

  getSubjectScrollToTaskHistoryList(): Observable<any> {
    return this.subjectScrollToTaskHistory.asObservable();
  }

  setSubjectNumberOfCompletedTask(number: number) {
    this.subjectNumberOfCompletedTask.next(number);
  }

  getSubjectNumberOfCompletedTask(): Observable<any> {
    return this.subjectNumberOfCompletedTask.asObservable();
  }




}
