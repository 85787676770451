<div>
  <!-- Left Sidebar (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)-->
  <aside id="leftsidebar" class="sidebar">
    <!-- Menu -->
    <div class="menu">
      <ng-scrollbar [style.height]="listMaxHeight + 'px'" visibility="hover">
        <ul class="list">
          <li class="sidebar-user-panel d-none">
            <div class="user-panel">
              <div class=" image">
                <img src={{userImg}} class="img-circle user-img-circle" alt="User Image" />
              </div>
            </div>
            <div class="profile-usertitle">
              <div class="sidebar-userpic-name">{{userFullName}} </div>
              <div class="profile-usertitle-job ">{{userType}} </div>
            </div>
          </li>
          <!-- Top Most level menu -->
          <li *ngFor="let sidebarItem of sidebarItems"
            [routerLinkActive]="sidebarItem.submenu.length != 0 ? 'active' : ''">
            <div class="header" *ngIf="sidebarItem.groupTitle === true">{{sidebarItem.title | translate}}</div>
            <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null" *ngIf="!sidebarItem.groupTitle;"
              [ngClass]="[sidebarItem.class]" (click)="callToggleMenuModal($event, sidebarItem.submenu,sidebarItem.title,sidebarItem.icon)"
              class="menu-top"  matTooltip="{{sidebarItem.title | translate}}" [matTooltipPosition]="'right'">
              <i [ngClass]="[sidebarItem.iconType]">{{sidebarItem.icon}}</i>
              <span class="hide-menu">{{sidebarItem.title | translate}}
              </span>
              <span *ngIf="sidebarItem.badge != '' " [ngClass]="[sidebarItem.badgeClass]">{{sidebarItem.badge}}</span>
            </a>
            <!-- First level menu -->
            <ul class="ml-menu d-none" *ngIf="sidebarItem.submenu.length > 0">
              
              <li *ngFor="let sidebarSubItem1 of sidebarItem.submenu"
                [routerLinkActive]="sidebarSubItem1.submenu.length > 0 ? '' : 'active'" >
                <a [routerLink]="sidebarSubItem1.submenu.length > 0 ? null : [sidebarSubItem1.path]"
                  (click)="callToggleMenu($event,sidebarSubItem1.submenu.length)" [ngClass]="[sidebarSubItem1.class]" >
                  {{sidebarSubItem1.title | translate}}
                  
                </a>
                <!-- Second level menu -->
                <ul class="ml-menu-2" *ngIf="sidebarSubItem1.submenu.length > 0">
                  <li *ngFor="let sidebarSubItem2 of sidebarSubItem1.submenu"
                    [routerLinkActive]="sidebarSubItem2.submenu.length > 0 ? '' : 'active'"
                    [matTooltipPosition]="'right'" class="{{!sidebarSubItem1.isHide ? 'd-none':''}}">
                    <a [routerLink]="sidebarSubItem2.submenu.length > 0 ? null : [sidebarSubItem2.path]"
                      (click)="callToggleMenu($event,sidebarSubItem2.submenu.length)"
                      [ngClass]="[sidebarSubItem2.class]" >
                      {{sidebarSubItem2.title | translate}} 
                    </a>
                    <!-- Third level menu -->
                    <ul class="ml-menu-3" *ngIf="sidebarSubItem2.submenu.length > 0">
                      <li *ngFor="let sidebarSubItem3 of sidebarSubItem2.submenu"
                        [routerLinkActive]="sidebarSubItem3.submenu.length > 0 ? '' : 'active'">
                        <a [routerLink]="sidebarSubItem3.submenu.length > 0 ? null : [sidebarSubItem3.path]"
                          (click)="callToggleMenu($event,sidebarSubItem3.submenu.length)"
                          [ngClass]="[sidebarSubItem3.class]" >
                          {{sidebarSubItem3.title | translate}}
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <a class="" *ngIf="!isPmsLoading && (helperService.checkUserRole(pmsRole.create) || helperService.checkUserRole(pmsRole.edit) || helperService.checkUserRole(pmsRole.edit) || helperService.checkUserRole(pmsRole.export) || helperService.checkUserRole(pmsRole.print) || helperService.checkUserRole(pmsRole.readonly))" (click)="pms()" class="menu-top"  matTooltip="{{'ADMINISTRATION_MENU.MAINTANENCE_PORTAL' | translate}}" [matTooltipPosition]="'right'">
              <i class="material-icons-two-tone" >build</i>
      
              <span class="hide-menu">{{'ADMINISTRATION_MENU.MAINTANENCE_PORTAL' | translate}}
              </span>
            </a>
            <a class="" *ngIf="isPmsLoading"class="menu-top">
              <app-loader></app-loader>
            </a>
          </li>
          <!-- <li>
            <a class="" *ngIf="!isPmsLoading && (helperService.checkUserRole(pmsRole.create) || helperService.checkUserRole(pmsRole.edit) || helperService.checkUserRole(pmsRole.edit) || helperService.checkUserRole(pmsRole.export) || helperService.checkUserRole(pmsRole.print) || helperService.checkUserRole(pmsRole.readonly))" (click)="adminPanel()" class="menu-top"  matTooltip="Admin Portal" [matTooltipPosition]="'right'">
              <i class="material-icons-two-tone" >admin_panel_settings</i>
      
              <span class="hide-menu"> Admin Portal
              </span>
            </a>
            <a class="" *ngIf="isPmsLoading"class="menu-top">
              <app-loader></app-loader>
            </a>
          </li> -->
          <li>
            <a class="" (click)="logout()" class="menu-top">
              <i class="material-icons-two-tone">power_settings_new</i>
              <span class="hide-menu">Logout
              </span>
            </a>
          </li>
        </ul>
      </ng-scrollbar>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>

