<div id="mySidenav" class="sidenav d-flex" [style]="{'width':navWidth}" *ngIf="routeInfoSide!=null">
  <div class="side-title">
    <h4> <i [ngClass]="['material-icons-two-tone']">{{routeInfoSide.parentIcon}}</i> {{routeInfoSide?.parent | translate}}  </h4>
  </div>
<a class="close-btn" (click)="closeNav()">
    <i [ngClass]="['material-icons-two-tone']">close</i>
</a>
  <!-- <ul>
    <li><a>Vehicle</a></li>
</ul> -->

  <!-- <ng-scrollbar visibility="hover" [autoHeightDisabled]="false"> -->
    <div class="mt-1">

    </div>
    <ul class="ml-menu  mt-5" *ngIf="sidebarItems.length > 0">
      <li>
      <li *ngFor="let sidebarSubItem1 of sidebarItems"
        [routerLinkActive]=" 'active'"  >
        <a [routerLink]="sidebarSubItem1.submenu.length > 0 ? null : [sidebarSubItem1.path]"
          (click)="callToggleMenu($event,sidebarSubItem1.submenu.length)" [ngClass]="[sidebarSubItem1.class]" *ngIf="!sidebarSubItem1?.isExternal">
          {{sidebarSubItem1.title | translate}}
        </a>
        <a [routerLink]="null"
          (click)="callExternal(sidebarSubItem1?.type)" [ngClass]="[sidebarSubItem1.class]" *ngIf="sidebarSubItem1?.isExternal">
          {{sidebarSubItem1.title | translate}}
        </a>
        <!-- Second level menu -->
        <ul class="ml-menu-2  child-menu" *ngIf="sidebarSubItem1.submenu.length > 0">
          <li *ngFor="let sidebarSubItem2 of sidebarSubItem1.submenu"
            [routerLinkActive]="sidebarSubItem2.submenu.length > 0 ? '' : 'active'" class="{{sidebarSubItem2.isHide ? 'd-none' : ''}}">
            <a [routerLink]="sidebarSubItem2.submenu.length > 0 ? null : [sidebarSubItem2.path]"
              (click)="callToggleMenu($event,sidebarSubItem2.submenu.length)" [ngClass]="[sidebarSubItem2.class]">
              {{sidebarSubItem2.title | translate}}  
            </a>
            <!-- Third level menu -->
            <ul class="ml-menu-3 child-menu" *ngIf="sidebarSubItem2.submenu.length > 0">
              <li *ngFor="let sidebarSubItem3 of sidebarSubItem2.submenu"
                [routerLinkActive]="sidebarSubItem3.submenu.length > 0 ? '' : 'active'">
                <a [routerLink]="sidebarSubItem3.submenu.length > 0 ? null : [sidebarSubItem3.path]"
                  (click)="callToggleMenu($event,sidebarSubItem3.submenu.length)" [ngClass]="[sidebarSubItem3.class]">
                  {{sidebarSubItem3.title | translate}}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  <!-- </ng-scrollbar> -->
</div>
