import { ROLES_LIST, DASHBOARD_ROLES, REQUEST_ROLES, INSPECTIONS_ROLES, ISSUES_ROLES, MANAGE_ATTACHMENT_ROLES, APPROVAL_WORKFLOW_LIST_ROLES,CONTACT_REPORTS_ROLES, VEHICLE_ASSIGNMENT_REPORTS_ROLES, VEHICLE_REPORTS_ROLES, CURRENT_USER_DETAILS, FLEET_ADMIN_ROLE } from 'src/app/shared/data/constants';
import { RouteInfo } from './sidebar.metadata';

var routesRoles = {};
routesRoles = ROLES_LIST
export const ROUTES: RouteInfo[] = [
  // {
  //   path: "",
  //   title: "MENUITEMS.MAIN.TEXT",
  //   iconType: "",
  //   icon: "",
  //   class: "",
  //   groupTitle: true,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["All"],
  //   submenu: [],
  // },

  // {
  //   path: "",
  //   title: "Extra Pages",
  //   iconType: "material-icons-two-tone",
  //   icon: "description",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["All"],
  //   submenu: [],
  // },

  {
    path: "dashboard",
    title: "MAIN_MENU.DASHBOARD",
    iconType: "material-icons-two-tone",
    icon: "dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [DASHBOARD_ROLES.create, DASHBOARD_ROLES.delete, DASHBOARD_ROLES.edit, DASHBOARD_ROLES.export, DASHBOARD_ROLES.print, DASHBOARD_ROLES.readonly],
    submenu: [],
  },

  {
    path: "approval-workflow-list",
    title: "MAIN_MENU.APPROVAL_WORKFLOW_LIST",
    iconType: "material-icons-two-tone",
    icon: "verified",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [APPROVAL_WORKFLOW_LIST_ROLES.create, APPROVAL_WORKFLOW_LIST_ROLES.delete, APPROVAL_WORKFLOW_LIST_ROLES.edit, APPROVAL_WORKFLOW_LIST_ROLES.export, APPROVAL_WORKFLOW_LIST_ROLES.print, APPROVAL_WORKFLOW_LIST_ROLES.readonly],
    submenu: [],
  },
  // {
  //   path: "maintanance/next-phase",
  //   title: "MAIN_MENU.ROUTE",
  //   iconType: "material-icons-two-tone",
  //   icon: "alt_route",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: [ "Admin"],
  //   submenu: [],
  // },

  {
    path: "maintanance/next-phase",
    title: "MAIN_MENU.VEHICLES",
    iconType: "material-icons-two-tone",
    icon: "directions_car",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [ROLES_LIST.VEHICLE_ASSIGNMENT_CREATE,ROLES_LIST.VEHICLE_ASSIGNMENT_EDIT,ROLES_LIST.VEHICLE_ASSIGNMENT_EXPORT,ROLES_LIST.VEHICLE_ASSIGNMENT_DELETE,ROLES_LIST.VEHICLE_ASSIGNMENT_PRINT,ROLES_LIST.VEHICLE_ASSIGNMENT_READONLY,
      ROLES_LIST.VEHICLE_LIST_CREATE,ROLES_LIST.VEHICLE_LIST_EDIT,ROLES_LIST.VEHICLE_LIST_EXPORT,ROLES_LIST.VEHICLE_LIST_DELETE,ROLES_LIST.VEHICLE_LIST_PRINT,ROLES_LIST.VEHICLE_LIST_READONLY,
      ROLES_LIST.EXPENSES_HISTORY_CREATE, ROLES_LIST.EXPENSES_HISTORY_DELETE, ROLES_LIST.EXPENSES_HISTORY_EDIT, ROLES_LIST.EXPENSES_HISTORY_EXPORT, ROLES_LIST.EXPENSES_HISTORY_PRINT, ROLES_LIST.EXPENSES_HISTORY_READONLY,
      ROLES_LIST.METER_HISTORY_CREATE, ROLES_LIST.METER_HISTORY_DELETE, ROLES_LIST.METER_HISTORY_EDIT, ROLES_LIST.METER_HISTORY_EXPORT, ROLES_LIST.METER_HISTORY_PRINT, ROLES_LIST.METER_HISTORY_READONLY],
    submenu: [{
      path: "vehicles/list",
      title: "VEHICLE_MENU.VEHICLE_LIST",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ROLES_LIST.VEHICLE_LIST_CREATE,ROLES_LIST.VEHICLE_LIST_EDIT,ROLES_LIST.VEHICLE_LIST_EXPORT,ROLES_LIST.VEHICLE_LIST_DELETE,ROLES_LIST.VEHICLE_LIST_PRINT,ROLES_LIST.VEHICLE_LIST_READONLY],
      submenu: [],
    },
    {
      path: "vehicles/vehicle-unavailability",
      title: 'VEHICLE_UNAVAILABILITY_MENU.VEHICLE_UNAVAILABILITY',
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ROLES_LIST.VEHICLE_LIST_CREATE,ROLES_LIST.VEHICLE_LIST_EDIT,ROLES_LIST.VEHICLE_LIST_EXPORT,ROLES_LIST.VEHICLE_LIST_DELETE,ROLES_LIST.VEHICLE_LIST_PRINT,ROLES_LIST.VEHICLE_LIST_READONLY],
      submenu: [],
    },
    {
      path: "vehicles/assignments",
      title: "VEHICLE_MENU.VEHICLE_ASSIGNMENT",
      icon: "",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      badge: "",
      badgeClass: "",
      role: [ROLES_LIST.VEHICLE_ASSIGNMENT_CREATE,ROLES_LIST.VEHICLE_ASSIGNMENT_EDIT,ROLES_LIST.VEHICLE_ASSIGNMENT_EXPORT,ROLES_LIST.VEHICLE_ASSIGNMENT_DELETE,ROLES_LIST.VEHICLE_ASSIGNMENT_PRINT,ROLES_LIST.VEHICLE_ASSIGNMENT_READONLY],
      submenu: [],
    },
    {
      path: "vehicles/meter-history",
      title: "VEHICLE_MENU.METER_HISTORY",
      icon: "",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      badge: "",
      badgeClass: "",
      role: [ ROLES_LIST.METER_HISTORY_CREATE, ROLES_LIST.METER_HISTORY_DELETE, ROLES_LIST.METER_HISTORY_EDIT, ROLES_LIST.METER_HISTORY_EXPORT, ROLES_LIST.METER_HISTORY_PRINT, ROLES_LIST.METER_HISTORY_READONLY],
      submenu: [],
    },
    {
      path: "vehicles/expensesHistory/list",
      title: "EXPENSES_HISTORY_MENU.EXPENSES_HISTORY",
      iconType: "material-icons-two-tone",
      class: "",
      icon: "",
      groupTitle: false,
      badge: "",
      badgeClass: "",
      role: [ ROLES_LIST.EXPENSES_HISTORY_CREATE, ROLES_LIST.EXPENSES_HISTORY_DELETE, ROLES_LIST.EXPENSES_HISTORY_EDIT, ROLES_LIST.EXPENSES_HISTORY_EXPORT, ROLES_LIST.EXPENSES_HISTORY_PRINT, ROLES_LIST.EXPENSES_HISTORY_READONLY],
      submenu: [],
    },
    {
      path: "fuelHistory/list",
      title: "MAIN_MENU.FUEL_HISTORY",
      iconType: "material-icons-two-tone",
      icon: "local_gas_station",
      class: "",
      groupTitle: false,
      badge: "",
      badgeClass: "",
      role: [ROLES_LIST.FUEL_HISTORY_CREATE, ROLES_LIST.FUEL_HISTORY_DELETE, ROLES_LIST.FUEL_HISTORY_EDIT, ROLES_LIST.FUEL_HISTORY_EXPORT, ROLES_LIST.FUEL_HISTORY_PRINT, ROLES_LIST.FUEL_HISTORY_READONLY],
      submenu: [],
    },
    {
      path: "vehicles/accessories/list",
      title: "MAIN_MENU.EQUIPMENT",
      iconType: "material-icons-two-tone",
      icon: "home_repair_service",
      class: "",
      groupTitle: false,
      badge: "",
      badgeClass: "",
      role: [ "Admin"],
      submenu: [],
    },
  ],
  },

  {
    path: "request",
    title: "MAIN_MENU.REQUEST",
    iconType: "material-icons-two-tone",
    icon: "car_crash",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [REQUEST_ROLES.create, REQUEST_ROLES.delete, REQUEST_ROLES.edit, REQUEST_ROLES.export, REQUEST_ROLES.print, REQUEST_ROLES.readonly],
    submenu: [],
  },

  {
    path: "",
    title: "MAIN_MENU.CONTACTS",
    iconType: "material-icons-two-tone",
    icon: "contact_phone",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [ROLES_LIST.CONTACTS_CREATE, ROLES_LIST.CONTACTS_DELETE, ROLES_LIST.CONTACTS_EDIT, ROLES_LIST.CONTACTS_EXPORT, ROLES_LIST.CONTACTS_PRINT, ROLES_LIST.CONTACTS_READONLY],
    submenu: [{
      path: "contacts/list",
      title: "MAIN_MENU.CONTACTS",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ROLES_LIST.CONTACTS_CREATE, ROLES_LIST.CONTACTS_DELETE, ROLES_LIST.CONTACTS_EDIT, ROLES_LIST.CONTACTS_EXPORT, ROLES_LIST.CONTACTS_PRINT, ROLES_LIST.CONTACTS_READONLY],
      submenu: [],
    },
    {
      path: "contacts/manage-leaves",
      title: "CONTACT_MENU.MANAGE_LEAVES",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ROLES_LIST.CONTACTS_CREATE, ROLES_LIST.CONTACTS_DELETE, ROLES_LIST.CONTACTS_EDIT, ROLES_LIST.CONTACTS_EXPORT, ROLES_LIST.CONTACTS_PRINT, ROLES_LIST.CONTACTS_READONLY],
      submenu: [],
    },
  ],
  },

  // {
  //   path: "fuelHistory/list",
  //   title: "MAIN_MENU.FUEL_HISTORY",
  //   iconType: "material-icons-two-tone",
  //   icon: "local_gas_station",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: [ROLES_LIST.FUEL_HISTORY_CREATE, ROLES_LIST.FUEL_HISTORY_DELETE, ROLES_LIST.FUEL_HISTORY_EDIT, ROLES_LIST.FUEL_HISTORY_EXPORT, ROLES_LIST.FUEL_HISTORY_PRINT, ROLES_LIST.FUEL_HISTORY_READONLY],
  //   submenu: [],
  // },

  // {
  //   path: "maintanance/next-phase",
  //   title: "MAIN_MENU.PLACES",
  //   iconType: "material-icons-two-tone",
  //   icon: "location_on",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: [ "Admin"],
  //   submenu: [],
  // },

  // {
  //   path: "maintanance/next-phase",
  //   title: "MAIN_MENU.SERVICE",
  //   iconType: "material-icons-two-tone",
  //   icon: "build_circle",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: [ "Admin"],
  //   submenu: [],
  // },

  {
    path: "inspections",
    title: "MAIN_MENU.INSPECTIONS",
    iconType: "material-icons-two-tone",
    icon: "content_paste_search",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [INSPECTIONS_ROLES.create, INSPECTIONS_ROLES.delete, INSPECTIONS_ROLES.edit, INSPECTIONS_ROLES.export, INSPECTIONS_ROLES.print, INSPECTIONS_ROLES.readonly],
    submenu: [],
  },
  // {
  //   path: "maintanance/next-phase",
  //   title: "MAIN_MENU.ISSUES",
  //   iconType: "material-icons-two-tone",
  //   icon: "warning",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: [ISSUES_ROLES.create, ISSUES_ROLES.delete, ISSUES_ROLES.edit, ISSUES_ROLES.export, ISSUES_ROLES.print, ISSUES_ROLES.readonly],
  //   submenu: [],
  // },

  // {
  //   path: "maintanance/next-phase",
  //   title: "MAIN_MENU.REMINDERS",
  //   iconType: "material-icons-two-tone",
  //   icon: "alarm",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: [ "Admin"],
  //   submenu: [],
  // },

  // {
  //   path: "maintanance/next-phase",
  //   title: "MAIN_MENU.DOCUMENTS",
  //   iconType: "material-icons-two-tone",
  //   icon: "file_present",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: [ "Admin"],
  //   submenu: [],
  // },

  {
    path: "",
    title: "MAIN_MENU.REPORTS",
    iconType: "material-icons-two-tone",
    icon: "bar_chart",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [CONTACT_REPORTS_ROLES.create, CONTACT_REPORTS_ROLES.delete, CONTACT_REPORTS_ROLES.edit, CONTACT_REPORTS_ROLES.export, CONTACT_REPORTS_ROLES.print, CONTACT_REPORTS_ROLES.readonly,
      VEHICLE_ASSIGNMENT_REPORTS_ROLES.create, VEHICLE_ASSIGNMENT_REPORTS_ROLES.delete, VEHICLE_ASSIGNMENT_REPORTS_ROLES.edit, VEHICLE_ASSIGNMENT_REPORTS_ROLES.export, VEHICLE_ASSIGNMENT_REPORTS_ROLES.print, VEHICLE_ASSIGNMENT_REPORTS_ROLES.readonly,
      VEHICLE_REPORTS_ROLES.create, VEHICLE_REPORTS_ROLES.delete, VEHICLE_REPORTS_ROLES.edit, VEHICLE_REPORTS_ROLES.export, VEHICLE_REPORTS_ROLES.print, VEHICLE_REPORTS_ROLES.readonly],
    submenu: [
      {
        path: "",
        title: "REPORT_MENU.VEHICLE_REPORTS",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: [ VEHICLE_REPORTS_ROLES.create, VEHICLE_REPORTS_ROLES.delete, VEHICLE_REPORTS_ROLES.edit, VEHICLE_REPORTS_ROLES.export, VEHICLE_REPORTS_ROLES.print, VEHICLE_REPORTS_ROLES.readonly],
        submenu: [
          {
            path: "reports/vehicleReports/list",
            title: "VEHICLE_SUMMARY_REPORT.VEHICLE_SUMMARY",
            iconType: "material-icons-two-tone",
            class: "",
            groupTitle: false,
            icon: "",
            badge: "",
            badgeClass: "",
            role: [ "Admin"],
            submenu: [],
          },
          {
            path: "reports/vehicleRegistrationAndInsurance/list",
            title: "VEHICLE_REGISTRATION_AND_INSURANCE_REPORT.VEHICLE_REGISTRATION_AND_INSURANCE",
            iconType: "material-icons-two-tone",
            class: "",
            groupTitle: false,
            icon: "",
            badge: "",
            badgeClass: "",
            role: [ "Admin"],
            submenu: [],
          },
          // {
          //   path: "maintanance/next-phase",
          //   title: "Vehicle Renewal Reminders",
          //   iconType: "material-icons-two-tone",
          //   class: "",
          //   groupTitle: false,
          //   icon: "",
          //   badge: "",
          //   badgeClass: "",
          //   role: [ "Admin"],
          //   submenu: [],
          // },
          // {
          //   path: "maintanance/next-phase",
          //   title: "Utilization Summary",
          //   iconType: "material-icons-two-tone",
          //   class: "",
          //   groupTitle: false,
          //   icon: "",
          //   badge: "",
          //   badgeClass: "",
          //   role: [ "Admin"],
          //   submenu: [],
          // },
        ],
      },
      {
        path: "",
        title: "REPORT_MENU.VEHICLE_ASSIGNMENT_REPORTS",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: [VEHICLE_ASSIGNMENT_REPORTS_ROLES.create, VEHICLE_ASSIGNMENT_REPORTS_ROLES.delete, VEHICLE_ASSIGNMENT_REPORTS_ROLES.edit, VEHICLE_ASSIGNMENT_REPORTS_ROLES.export, VEHICLE_ASSIGNMENT_REPORTS_ROLES.print, VEHICLE_ASSIGNMENT_REPORTS_ROLES.readonly],
        submenu: [
          {
            path: "reports/vehicleAssignmentReports/vehicleAssignmentLog",
            title: "REPORT_MENU.ASSIGNMENT_SUMMARY",
            iconType: "material-icons-two-tone",
            class: "",
            groupTitle: false,
            icon: "",
            badge: "",
            badgeClass: "",
            role: [ "Admin"],
            submenu: [],
          },
          {
            path: "reports/vehicleAssignmentReports/vehicleAssignmentSummary",
            title: "REPORT_MENU.ASSIGNMENT_DETAILS",
            iconType: "material-icons-two-tone",
            class: "",
            groupTitle: false,
            icon: "",
            badge: "",
            badgeClass: "",
            role: [ "Admin"],
            submenu: [],
          },
        ],
      },
      {
        path: "",
        title: "CONTACT_SUMMARY_REPORT.CONTACT_REPORTS",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: [CONTACT_REPORTS_ROLES.create, CONTACT_REPORTS_ROLES.delete, CONTACT_REPORTS_ROLES.edit, CONTACT_REPORTS_ROLES.export, CONTACT_REPORTS_ROLES.print, CONTACT_REPORTS_ROLES.readonly],
        submenu: [
                {
        path: "reports/contact/list",
        title: "CONTACT_SUMMARY_REPORT.CONTACT_SUMMARY",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: [],
        submenu: [],
      },
        ],
      },
      {
        path: "reports/fuelHistoryReports/list",
        title: "REPORT_MENU.FUEL_HISTORY_REPORTS",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: [],
        submenu: [],
      },
      {
        path: "reports/meterHistoryReports/list",
        title: "REPORT_MENU.METER_HISTORY_REPORTS",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: [],
        submenu: [],
      },
      {
        path: "",
        title: "REPORT_MENU.OPERATOR_SUMMARY_REPORT",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: [],
        submenu: [
          {
            path: "reports/operatorSummaryReports/list",
            title: "REPORT_MENU.OPEARATOR_SUMMARY",
            iconType: "material-icons-two-tone",
            class: "",
            groupTitle: false,
            icon: "",
            badge: "",
            badgeClass: "",
            role: [],
            submenu: [],
          },
        ],
      },
      {
        path: "",
        title: "DEPRECIATION_REPORTS_MENU.DEPRECIATION_REPORTS",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: [],
        submenu: [
          {
            path: "reports/depreciationReports/register-monthly-report",
            title: "DEPRECIATION_REPORTS_MENU.ASSET_DEPRECIATION_REGISTER_MONTHLY_REPORT",
            iconType: "material-icons-two-tone",
            class: "",
            groupTitle: false,
            icon: "",
            badge: "",
            badgeClass: "",
            role: [],
            submenu: [],
          },
          {
            path: "reports/depreciationReports/detail-summary-report",
            title: "DEPRECIATION_REPORTS_MENU.DETAIL_SUMMARY_REPORT",
            iconType: "material-icons-two-tone",
            class: "",
            groupTitle: false,
            icon: "",
            badge: "",
            badgeClass: "",
            role: [],
            submenu: [],
          },
          {
            path: "reports/depreciationReports/category-summary-report",
            title: "DEPRECIATION_REPORTS_MENU.CATEGORY_SUMMARY_REPORT",
            iconType: "material-icons-two-tone",
            class: "",
            groupTitle: false,
            icon: "",
            badge: "",
            badgeClass: "",
            role: [],
            submenu: [],
          },
        ],
      },
      // {
      //   path: "maintanance/next-phase",
      //   title: "Custom Reports",
      //   iconType: "material-icons-two-tone",
      //   class: "",
      //   groupTitle: false,
      //   icon: "",
      //   badge: "",
      //   badgeClass: "",
      //   role: [ "Admin"],
      //   submenu: [],
      // },
    ],
  },

  {
    path: "",
    title: "MASTER_MENU.MASTER",
    iconType: "material-icons-two-tone",
    icon: "table_view",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT, ROLES_LIST.MASTER_COMMON_CREATE, ROLES_LIST.MASTER_COMMON_DELETE, ROLES_LIST.MASTER_COMMON_EDIT, ROLES_LIST.MASTER_COMMON_EXPORT, ROLES_LIST.MASTER_COMMON_PRINT, ROLES_LIST.MASTER_COMMON_READONLY,
          ROLES_LIST.MASTER_CONTACT_CREATE, ROLES_LIST.MASTER_CONTACT_DELETE, ROLES_LIST.MASTER_CONTACT_EDIT, ROLES_LIST.MASTER_CONTACT_EXPORT, ROLES_LIST.MASTER_CONTACT_PRINT, ROLES_LIST.MASTER_CONTACT_READONLY,ROLES_LIST.MASTER_OPERATOR_CREATE, ROLES_LIST.MASTER_OPERATOR_EDIT, ROLES_LIST.MASTER_OPERATOR_EXPORT, ROLES_LIST.MASTER_OPERATOR_PRINT, ROLES_LIST.MASTER_OPERATOR_READONLY, 
          ROLES_LIST.MASTER_OPERATOR_DELETE,ROLES_LIST.MASTER_VEHICLES_CREATE, ROLES_LIST.MASTER_VEHICLES_DELETE, ROLES_LIST.MASTER_VEHICLES_EDIT, ROLES_LIST.MASTER_VEHICLES_EXPORT, ROLES_LIST.MASTER_VEHICLES_PRINT, ROLES_LIST.MASTER_VEHICLES_READONLY],
    submenu: [{
      path: "",
      title: "MAIN_MENU.VEHICLE_MASTER",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT, ROLES_LIST.MASTER_VEHICLES_CREATE, ROLES_LIST.MASTER_VEHICLES_DELETE, ROLES_LIST.MASTER_VEHICLES_EDIT, ROLES_LIST.MASTER_VEHICLES_EXPORT, ROLES_LIST.MASTER_VEHICLES_PRINT, ROLES_LIST.MASTER_VEHICLES_READONLY],
      submenu: [{
        path: "master/plates",
      title: "MASTER_MENU.PLATE",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
      submenu: [],
      },
      {
        path: "master/vehicle-plates/list",
      title: "MASTER_MENU.VEHICLE_PLATE",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
      submenu: [],
      },
      {
        path: "master/vehicle-ownerships/list",
      title: "MASTER_MENU.VEHICLE_OWNERSHIP",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
      submenu: [],
      },
      {
        path: "master/vehicleYear/list",
      title: "MASTER_MENU.VEHICLE_YEAR",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
      submenu: [],
      },
      {
        path: "master/vehicle-make/list",
      title: "MASTER_MENU.VEHICLE_MAKE",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
      submenu: [],
      },
      {
        path: "master/vehicle-model/list",
      title: "MASTER_MENU.VEHICLE_MODEL",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
      submenu: [],
      },
      {
        path: "master/vehicle-color/list",
      title: "MASTER_MENU.VEHICLE_COLOR",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
      submenu: [],
      },
      {
        path: "master/vehicleType/list",
      title: "MASTER_MENU.VEHICLE_TYPE",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
      submenu: [],
      },
      {
        path: "master/vehicleBodyType/list",
      title: "MASTER_MENU.VEHICLE_BODY_TYPE",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
      submenu: [],
      },
      {
        path: "master/vehicle-categories/list",
      title: "MASTER_MENU.VEHICLE_CATEGORY",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
      submenu: [],
      },
      {
        path: "master/vehicleClass/list",
      title: "MASTER_MENU.VEHICLE_CLASS",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
      submenu: [],
      },
      {
        path: "master/fuelType/list",
      title: "MASTER_MENU.FUEL_TYPE",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
      submenu: [],
      },
      {
        path: "master/vehicle-drive-type/list",
      title: "MASTER_MENU.DRIVE_TYPE",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
      submenu: [],
      },
      {
        path: "master/brakeSystem/list",
      title: "MASTER_MENU.BRAKE_SYSTEM",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
      submenu: [],
      },
      {
        path: "master/vehicle-transmission-type/list",
      title: "MASTER_MENU.TRANSMISSION_TYPE",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
      submenu: [],
      },
      {
        path: "master/inspection-checklist",
      title: "INSPECTION_CHECKLIST_MENU.INSPECTION_CHECKLIST",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
      submenu: [],
      }
    ],
    },
    {
      path: "",
      title: "MAIN_MENU.OPERATOR_MASTER",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT, ROLES_LIST.MASTER_OPERATOR_CREATE, ROLES_LIST.MASTER_OPERATOR_EDIT, ROLES_LIST.MASTER_OPERATOR_EXPORT, ROLES_LIST.MASTER_OPERATOR_PRINT, ROLES_LIST.MASTER_OPERATOR_READONLY, ROLES_LIST.MASTER_OPERATOR_DELETE],
      submenu: [
        {
          path: "master/driverCategory/list",
      title: "MASTER_MENU.DRIVER_CATEGORY",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
      submenu: [],
        },
        {
          path: "master/driverType/list",
            title: "MASTER_MENU.DRIVER_TYPE",
            iconType: "material-icons-two-tone",
            class: "",
            groupTitle: false,
            icon: "",
            badge: "",
            badgeClass: "",
            role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
            submenu: [],
        },
        {
          path: "master/holidays",
          title: "MASTER_MENU.HOLIDAYS",
          iconType: "material-icons-two-tone",
          class: "",
          groupTitle: false,
          icon: "",
          badge: "",
          badgeClass: "",
          role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
          submenu: [],
      },
    ],
    },
    {
      path: "",
      title: "MAIN_MENU.CONTACT_MASTER",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT, ROLES_LIST.MASTER_CONTACT_CREATE, ROLES_LIST.MASTER_CONTACT_DELETE, ROLES_LIST.MASTER_CONTACT_EDIT, ROLES_LIST.MASTER_CONTACT_EXPORT, ROLES_LIST.MASTER_CONTACT_PRINT, ROLES_LIST.MASTER_CONTACT_READONLY],
      submenu:[{
        path: "master/departments/list",
      title: "MASTER_MENU.DEPARTMENT",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
      submenu: [],
      },
      {
        path: "master/jobTitle",
      title: "MASTER_MENU.JOB_TITLE",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
      submenu: [],
      }
      ],
    },
    {
      path: "",
      title: "MAIN_MENU.COMMON_MASTER",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT, ROLES_LIST.MASTER_COMMON_CREATE, ROLES_LIST.MASTER_COMMON_DELETE, ROLES_LIST.MASTER_COMMON_EDIT, ROLES_LIST.MASTER_COMMON_EXPORT, ROLES_LIST.MASTER_COMMON_PRINT, ROLES_LIST.MASTER_COMMON_READONLY],
      submenu:[
      {
        path: "master/centralCode/list",
      title: "MASTER_MENU.CENTRAL_CODE",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin"],
      // role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
      submenu: [],
      },
      // {
      //   path: "master/custom-code-module/list",
      // title: "CUSTOM_CODE_MODULES_MENU.CUSTOM_CODE_MODULE",
      // iconType: "material-icons-two-tone",
      // class: "",
      // groupTitle: false,
      // icon: "",
      // badge: "",
      // badgeClass: "",
      // role: [ "Admin"],
      // // role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
      // submenu: [],
      // },
      {
        path: "master/vehicleStateProvince/list",
      title: "MASTER_MENU.VEHICLE_STATE/PROVINCE",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
      submenu: [],
      },
      {
        path: "master/vehicle-country/list",
      title: "CONTACT_MENU.COUNTRY",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
      submenu: [],
      },
    ]
    }

    // path: "master/plates",
    //   title: "MASTER_MENU.PLATE",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],

    // {
    //   path: "master/vehicle-plates/list",
    //   title: "MASTER_MENU.VEHICLE_PLATE",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
    // {
    //   path: "master/departments/list",
    //   title: "MASTER_MENU.DEPARTMENT",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },

    // {
    //   path: "master/vehicle-ownerships/list",
    //   title: "MASTER_MENU.VEHICLE_OWNERSHIP",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
    // {
    //   path: "master/vehicleYear/list",
    //   title: "MASTER_MENU.VEHICLE_YEAR",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },

    // {
    //   path: "master/vehicle-make/list",
    //   title: "MASTER_MENU.VEHICLE_MAKE",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
    // {
    //   path: "master/vehicle-model/list",
    //   title: "MASTER_MENU.VEHICLE_MODEL",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },

    // {
    //   path: "master/vehicleStateProvince/list",
    //   title: "MASTER_MENU.VEHICLE_STATE/PROVINCE",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
    // {
    //   path: "master/vehicle-color/list",
    //   title: "MASTER_MENU.VEHICLE_COLOR",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },

    // {
    //   path: "master/vehicleType/list",
    //   title: "MASTER_MENU.VEHICLE_TYPE",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
    // {
    //   path: "master/vehicleBodyType/list",
    //   title: "MASTER_MENU.VEHICLE_BODY_TYPE",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },

    // {
    //   path: "master/vehicle-categories/list",
    //   title: "MASTER_MENU.VEHICLE_CATEGORY",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
    // {
    //   path: "master/vehicleClass/list",
    //   title: "MASTER_MENU.VEHICLE_CLASS",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },

    // {
    //   path: "master/driverCategory/list",
    //   title: "MASTER_MENU.DRIVER_CATEGORY",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
    // {
    //   path: "master/driverType/list",
    //   title: "MASTER_MENU.DRIVER_TYPE",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },

    // {
    //   path: "master/fuelType/list",
    //   title: "MASTER_MENU.FUEL_TYPE",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
    // {
    //   path: "master/vehicle-drive-type/list",
    //   title: "MASTER_MENU.DRIVE_TYPE",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
    // {
    //   path: "master/brakeSystem/list",
    //   title: "MASTER_MENU.BRAKE_SYSTEM",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
    // {
    //   path: "master/vehicle-transmission-type/list",
    //   title: "MASTER_MENU.TRANSMISSION_TYPE",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
    // {
    //   path: "master/maintenanceClassification/list",
    //   title: "MASTER_MENU.MAINTENANCE_CLASSIFICATION",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin"],
    //   submenu: [],
    // },
    // {
    //   path: "master/vehicle-category-assign-list/list",
    //   title: "MASTER_MENU.CATEGORY_ASSIGN", // name should be change
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin"],
    //   submenu: [],
    // },
    // {
    //   path: "master/driverTypeClassAssign/list",
    //   title: "MASTER_MENU.DRIVER_TYPE_VEHICLE_CLASS_ASSIGN",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin"],
    //   submenu: [],
    // },
    // {
    //   path: "master/centralCode/list",
    //   title: "MASTER_MENU.CENTRAL_CODE",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
    // {
    //   path: "master/jobTitle",
    //   title: "MASTER_MENU.JOB_TITLE",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
    // {
    //   path: "master/holidays",
    //   title: "MASTER_MENU.HOLIDAYS",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
    // {
    //   path: "master/custom-code-module/list",
    //   title: "CUSTOM_CODE_MODULES_MENU.CUSTOM_CODE_MODULE",
    //   iconType: "material-icons-two-tone",
    //   class: "",
    //   groupTitle: false,
    //   icon: "",
    //   badge: "",
    //   badgeClass: "",
    //   role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
    //   submenu: [],
    // },
  ],
  },

  // {
  //   path: "manageAttachment",
  //   title: "MAIN_MENU.MANAGE_ATTACHMENT",
  //   iconType: "material-icons-two-tone",
  //   icon: "attachment",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: [MANAGE_ATTACHMENT_ROLES.create, MANAGE_ATTACHMENT_ROLES.delete, MANAGE_ATTACHMENT_ROLES.edit, MANAGE_ATTACHMENT_ROLES.export, MANAGE_ATTACHMENT_ROLES.print, MANAGE_ATTACHMENT_ROLES.readonly],
  //   submenu: []
  // },

  {
    path: "",
    title: "MAIN_MENU.ADMINISTRATION",
    iconType: "material-icons-two-tone",
    icon: "admin_panel_settings",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [ "Admin",ROLES_LIST.ADMINISTRATION_CREATE,ROLES_LIST.ADMINISTRATION_DELETE,ROLES_LIST.ADMINISTRATION_EDIT,ROLES_LIST.ADMINISTRATION_EXPORT,ROLES_LIST.ADMINISTRATION_PRINT,ROLES_LIST.ADMINISTRATION_PRINT],
    submenu: [
      {
        path: "administration/systemApplication",
        title: "ADMINISTRATION_MENU.SYSTEM_APPLICATION",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: [ "Admin",ROLES_LIST.ADMINISTRATION_CREATE,ROLES_LIST.ADMINISTRATION_DELETE,ROLES_LIST.ADMINISTRATION_EDIT,ROLES_LIST.ADMINISTRATION_EXPORT,ROLES_LIST.ADMINISTRATION_PRINT,ROLES_LIST.ADMINISTRATION_PRINT],
        submenu: [],
      },
      {
        path: "master/custom-code-module/list",
      title: "CUSTOM_CODE_MODULES_MENU.CUSTOM_CODE_MODULE",
      iconType: "material-icons-two-tone",
      class: "",
      groupTitle: false,
      icon: "",
      badge: "",
      badgeClass: "",
      role: [ "Admin"],
      // role: [ "Admin",ROLES_LIST.MASTER_CREATE,ROLES_LIST.MASTER_DELETE,ROLES_LIST.MASTER_EDIT,ROLES_LIST.MASTER_EXPORT,ROLES_LIST.MASTER_PRINT,ROLES_LIST.MASTER_PRINT ],
      submenu: [],
      },
      {
        path: "administration/systemModule",
        title: "ADMINISTRATION_MENU.SYSTEM_MODULE",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: [ "Admin",ROLES_LIST.ADMINISTRATION_CREATE,ROLES_LIST.ADMINISTRATION_DELETE,ROLES_LIST.ADMINISTRATION_EDIT,ROLES_LIST.ADMINISTRATION_EXPORT,ROLES_LIST.ADMINISTRATION_PRINT,ROLES_LIST.ADMINISTRATION_PRINT],
        submenu: [],
      },
      {
        path: "administration/manageRoles",
        title: "ADMINISTRATION_MENU.MANAGE_ROLES",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: [ "Admin",ROLES_LIST.ADMINISTRATION_CREATE,ROLES_LIST.ADMINISTRATION_DELETE,ROLES_LIST.ADMINISTRATION_EDIT,ROLES_LIST.ADMINISTRATION_EXPORT,ROLES_LIST.ADMINISTRATION_PRINT,ROLES_LIST.ADMINISTRATION_PRINT],
        submenu: [
          {
            path: "administration/manageRoles/manage-role-classification",
            title: "ADMINISTRATION_MENU.ROLES_CLASSIFICATION",
            iconType: "material-icons-two-tone",
            class: "",
            groupTitle: false,
            icon: "",
            badge: "",
            badgeClass: "",
            role: ["Admin"],
            // role: ["Admin",ROLES_LIST.ADMINISTRATION_CREATE,ROLES_LIST.ADMINISTRATION_DELETE,ROLES_LIST.ADMINISTRATION_EDIT,ROLES_LIST.ADMINISTRATION_EXPORT,ROLES_LIST.ADMINISTRATION_PRINT,ROLES_LIST.ADMINISTRATION_PRINT],
            submenu: [],
            isHide:!CURRENT_USER_DETAILS?.role?.includes(FLEET_ADMIN_ROLE)
          },
          {
            path: "administration/manageRoles",
            title: "ADMINISTRATION_MENU.ROLES_MANAGEMENT",
            iconType: "material-icons-two-tone",
            class: "",
            groupTitle: false,
            icon: "",
            badge: "",
            badgeClass: "",
            role: ["Admin",ROLES_LIST.ADMINISTRATION_CREATE,ROLES_LIST.ADMINISTRATION_DELETE,ROLES_LIST.ADMINISTRATION_EDIT,ROLES_LIST.ADMINISTRATION_EXPORT,ROLES_LIST.ADMINISTRATION_PRINT,ROLES_LIST.ADMINISTRATION_PRINT],
            submenu: [],
          }
        ],
      },
      {
        path: "administration/manageUsers",
        title: "ADMINISTRATION_MENU.MANAGE_USERS",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: [ "Admin",ROLES_LIST.ADMINISTRATION_CREATE,ROLES_LIST.ADMINISTRATION_DELETE,ROLES_LIST.ADMINISTRATION_EDIT,ROLES_LIST.ADMINISTRATION_EXPORT,ROLES_LIST.ADMINISTRATION_PRINT,ROLES_LIST.ADMINISTRATION_PRINT],
        submenu: [],
      },
      {
        path: "administration/approvedWorkflows",
        title: "ADMINISTRATION_MENU.APPROVED_WORKFLOWS",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: [ "Admin",ROLES_LIST.ADMINISTRATION_CREATE,ROLES_LIST.ADMINISTRATION_DELETE,ROLES_LIST.ADMINISTRATION_EDIT,ROLES_LIST.ADMINISTRATION_EXPORT,ROLES_LIST.ADMINISTRATION_PRINT,ROLES_LIST.ADMINISTRATION_PRINT],
        submenu: [],
      },
      {
        path: "administration/workflowUserGroup",
        title: "ADMINISTRATION_MENU.WORKFLOW_USER_GROUP",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: [ "Admin",ROLES_LIST.ADMINISTRATION_CREATE,ROLES_LIST.ADMINISTRATION_DELETE,ROLES_LIST.ADMINISTRATION_EDIT,ROLES_LIST.ADMINISTRATION_EXPORT,ROLES_LIST.ADMINISTRATION_PRINT,ROLES_LIST.ADMINISTRATION_PRINT],
        submenu: [],
      },
      {
        path: "",
        title: "ADMINISTRATION_MENU.PORTAL_LOGIN",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: [ "Admin",ROLES_LIST.ADMINISTRATION_CREATE,ROLES_LIST.ADMINISTRATION_DELETE,ROLES_LIST.ADMINISTRATION_EDIT,ROLES_LIST.ADMINISTRATION_EXPORT,ROLES_LIST.ADMINISTRATION_PRINT,ROLES_LIST.ADMINISTRATION_PRINT],
        submenu: [],
        isExternal:true,
        type:"PORTAL"
      },
      // {
      //   path: "administration/portal",
      //   title: "ADMINISTRATION_MENU.PORTAL_LOGIN",
      //   iconType: "material-icons-two-tone",
      //   class: "",
      //   groupTitle: false,
      //   icon: "",
      //   badge: "",
      //   badgeClass: "",
      //   role: [ "Admin",ROLES_LIST.ADMINISTRATION_CREATE,ROLES_LIST.ADMINISTRATION_DELETE,ROLES_LIST.ADMINISTRATION_EDIT,ROLES_LIST.ADMINISTRATION_EXPORT,ROLES_LIST.ADMINISTRATION_PRINT,ROLES_LIST.ADMINISTRATION_PRINT],
      //   submenu: [],
      //   isExternal:true
      // },
      // {
      //   path: "maintanance/next-phase",
      //   title: "ADMINISTRATION_MENU.APPROVAL_MATRIX",
      //   iconType: "material-icons-two-tone",
      //   class: "",
      //   groupTitle: false,
      //   icon: "",
      //   badge: "",
      //   badgeClass: "",
      //   role: [ "Admin"],
      //   submenu: [],
      // },
      // {
      //   path: "maintanance/next-phase",
      //   title: "ADMINISTRATION_MENU.SECURITY_MATRIX_OF_USERS",
      //   iconType: "material-icons-two-tone",
      //   class: "",
      //   groupTitle: false,
      //   icon: "",
      //   badge: "",
      //   badgeClass: "",
      //   role: [ "Admin"],
      //   submenu: [],
      // },
      // {
      //   path: "maintanance/next-phase",
      //   title: "ADMINISTRATION_MENU.SECURITY_MATRIX_OF_ROLES",
      //   iconType: "material-icons-two-tone",
      //   class: "",
      //   groupTitle: false,
      //   icon: "",
      //   badge: "",
      //   badgeClass: "",
      //   role: [ "Admin"],
      //   submenu: [],
      // },
      // {
      //   path: "administration/activityLog",
      //   title: "ADMINISTRATION_MENU.ACTIVITY_LOG",
      //   iconType: "material-icons-two-tone",
      //   class: "",
      //   groupTitle: false,
      //   icon: "",
      //   badge: "",
      //   badgeClass: "",
      //   role: [ "Admin"],
      //   submenu: [],
      // },

      {
        path: "administration/companySettings",
        title: "ADMINISTRATION_MENU.COMPANY_SETTING",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: [ "Admin",FLEET_ADMIN_ROLE],
        // role: [ "Admin",ROLES_LIST.ADMINISTRATION_CREATE,ROLES_LIST.ADMINISTRATION_DELETE,ROLES_LIST.ADMINISTRATION_EDIT,ROLES_LIST.ADMINISTRATION_EXPORT,ROLES_LIST.ADMINISTRATION_PRINT,ROLES_LIST.ADMINISTRATION_PRINT],
        submenu: [],
      },
      {
        path: "administration/systemSettings",
        title: "SYSTEM_SETTINGS_MENU.SYSTEM_SETTINGS",
        iconType: "material-icons-two-tone",
        class: "",
        groupTitle: false,
        icon: "",
        badge: "",
        badgeClass: "",
        role: [ "Admin",ROLES_LIST.ADMINISTRATION_CREATE,ROLES_LIST.ADMINISTRATION_DELETE,ROLES_LIST.ADMINISTRATION_EDIT,ROLES_LIST.ADMINISTRATION_EXPORT,ROLES_LIST.ADMINISTRATION_PRINT,ROLES_LIST.ADMINISTRATION_PRINT],
        submenu: [],
      },
      // {
      //   path: "maintanance/next-phase",
      //   title: "ADMINISTRATION_MENU.MAINTENANCE_MANAGEMENT",
      //   iconType: "material-icons-two-tone",
      //   class: "",
      //   groupTitle: false,
      //   icon: "",
      //   badge: "",
      //   badgeClass: "",
      //   role: [ "Admin"],
      //   submenu: [],
      // },

    ],
  },
];
