import { Injectable } from '@angular/core';
import * as moment from "moment";
import { FCLCLASSES } from 'src/app/home/vehicles/vehicle-assignments/models/calender.model';
import { COLORS, Toast, VahicleAssignmentStatus, FLEET_ADMIN_ROLE, CURRENT_USER_DETAILS } from '../data/constants';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/service/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class HelpersService {
  private subjectApprovalLevelId =new BehaviorSubject(null);
  constructor(
    private translateService :TranslateService,
    private authService: AuthService,

    // private userRoleData: any
    ) {
      // this.userRoleData = JSON.parse(localStorage.getItem('currentUser'));
    }



  getDateFromDbFormatted(dateInUtc: any) {
    if (dateInUtc) {
      return moment(dateInUtc).local().format("YYYY-MM-DDTHH:mm:ssZ");
    } else {
      return '';
    }
  }
  getDateFromDbFormattedDDMMYYYY(dateInUtc: any) {
    if(dateInUtc) {
      return moment(dateInUtc).local().format("DD-MM-YYYY hh:mm A")
    } else{
      return '';
    }

  }
  getDateFromDb(dateInUtc: Date) {

    return moment(dateInUtc).local()
  }
  getTimeFromDb(dateInUtc: any) {

    return moment(dateInUtc).local().format("hh:mm A")
  }

  getDbTime(time: any){
    let utcTime = moment(time, 'HH:mm').format('hh:mm A')
    return utcTime;
  }

  getDateInputFormatted(dateInUtc: any) {

    return moment(dateInUtc).format("YYYY-MM-DDTHH:mm:ssZ")
  }

  setDateForDb(dateInLocal: Date) {
    // console.log(dateInLocal);
    if (dateInLocal) {
      return moment.utc(dateInLocal).format("YYYY-MM-DDTHH:mm:ssZ");
    } else {
      return "";
    }
  }
  setDateFormatted(dateInLocal: Date) {
    // console.log(dateInLocal);
    if (dateInLocal) {
      return moment(dateInLocal).format("YYYY-MM-DDTHH:mm:ssZ");
    } else {
      return "";
    }
  }
  setDateDepreciationForDb(dateInLocal: Date) {
    // console.log(dateInLocal);
    if (dateInLocal) {
      return moment.utc(dateInLocal).format("YYYY-MM-DD HH:mm:ssZ");
    } else {
      return "";
    }
  }

  formatDateWithoutConvert(date: Date) {
    if (date) {
      return moment(date).format("YYYY-MM-DD") + " 00:00:00+00";
    } else {
      return "";
    }
  }

  showDateWithoutConvert(date: Date) {
    if (date) {
      return moment(date).format("DD-MM-YYYY");
    } else {
      return "";
    }
  }

  setDateForDbAvailability(dateInLocal: Date) {
    // console.log(dateInLocal);
    if (dateInLocal) {
      return moment(dateInLocal).format("YYYY-MM-DD HH:mm:ss+");
    } else {
      return "";
    }
  }

  setDateTime(date: any, time: any){
    let utcDate = moment(date).utc().format('DD-MM-YYYY');
    // let utcTime = moment(time, 'hh:mm A').format('HH:mm');
    return utcDate+" "+time;
    // return utcDate + 'T' + utcTime + 'Z';
  }
  getDateTimeFromUtc(date: any, time: any){
    let utcDate = moment(date).format('DD-MM-YYYY');
    // let utcTime = moment(time, 'hh:mm A').format('HH:mm');
    return moment(utcDate+" "+time).local().format('DD-MM-YYYY hh:mm A');
    // return utcDate + 'T' + utcTime + 'Z';
  }

  getDateFromLocal(date: any){
    if (date) {
      let utcDate = moment(date).format('DD-MM-YYYY');
      return moment(utcDate).local().format('DD-MM-YYYY');
    }
  }

  showErrorToast(msg:string){
    Toast.fire({
      icon: 'error',
      title: msg,

    })
  }

  showSuccessToast(msg:string){
    Toast.fire({
      icon: 'success',
      title: msg
    })
  }

  showInfoToast(msg:string){
    Toast.fire({
      icon: 'success',
      title: msg
    })
  }

  showCreateSuccessToast() {
    Toast.fire({
      icon: 'success',
      title: this.translateService.instant('MASTER_MENU.CREATED_SUCCESSFULLY'),
    })
  }

  showDeleteSuccessToast() {
    Toast.fire({
      icon: 'success',
      title: this.translateService.instant('MASTER_MENU.DELETED_SUCCESSFULLY'),
    })
  }

  showUpdateSuccessToast() {
    Toast.fire({
      icon: 'success',
      title: this.translateService.instant('MASTER_MENU.UPDATED_SUCCESSFULLY'),
    })
  }

  getRandomColor() : string{
    return COLORS[(Math.random() * COLORS.length) | 0].toString();
  }

  getRandomFCClass() : string{
    return FCLCLASSES[(Math.random() * FCLCLASSES.length) | 0].toString();
  }

  getVehicleAssignmentStatusFromDb(status: string){
    for (let i = 0; i < VahicleAssignmentStatus.length; i++) {
      const element = VahicleAssignmentStatus[i];
      if (element.value == status) {
        return element.name;
      }
    }
  }

  setDVehicleAssignmentStatusToDb(name: string){
    for (let i = 0; i < VahicleAssignmentStatus.length; i++) {
      const element = VahicleAssignmentStatus[i];
      if (element.name == name) {
        return element.value;
      }
    }
  }

  toUpperCase(str:string){
    str = str ? str.charAt(0).toUpperCase() + str.substr(1).toLowerCase() : '';

    return str;
  }

  checkUserRole(role: string) {
    return this.authService.currentUserValue.role?.includes(role) || this.authService.currentUserValue?.role.includes(FLEET_ADMIN_ROLE);
  }
  isFleetAdmin(role: string) {
    return this.authService.currentUserValue?.role.includes(FLEET_ADMIN_ROLE);
  }

  setSubjectApprovalLevelId(approvalLevel: any) {
    this.subjectApprovalLevelId.next(approvalLevel);
  }

  getSubjectApprovalLevelId(): Observable<any> {
    return this.subjectApprovalLevelId.asObservable();
  }


  generateFilterArray(f){

  }
}
