import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { LookUpElement } from 'src/app/shared/models/common-data.model';
import { DataTableColumn } from 'src/app/shared/models/data-table.model';
import { FilterData, FilterFilterFormData, FilterVm } from 'src/app/shared/models/filter-data.model';
import { FilterSharedService } from 'src/app/shared/services/filter-shared.service';
import { MatAccordion } from '@angular/material/expansion';
import { DateAdapter } from '@angular/material/core';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-filter-parameters',
  templateUrl: './filter-parameters.component.html',
  styleUrls: ['./filter-parameters.component.scss']
})

export class FilterParametersComponent implements OnInit, AfterViewInit {

  @ViewChild('template', { static: true }) template: any;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  modalRef: BsModalRef;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  filterloadDataEvent = new BehaviorSubject(null);
  filterDataSubject: Subscription;
  filterData: FilterData;
  sortForm: FormGroup;
  coloumnsData: DataTableColumn[];


  filterOutputArray: FilterVm[];

  filterBySearch = "";
  valueLabel = "Value";
  fromLabel = "From";


  loadEqueationDataArr: any[] = [];

  stringDataDropDown: LookUpElement[] = [
    {
      id: "Equal",
      name: "Is Equal To"
    },
    {
      id: "NotEqual",
      name: "Is Not Equal To"
    },
    {
      id: "Contains",
      name: "Contains"
    },
    {
      id: "StartsWith",
      name: "Starts With"
    },
    {
      id: "EndsWith",
      name: "Ends With"
    },
    {
      id: "IsEmpty",
      name: "Is Empty"
    },
    {
      id: "IsNotEmpty",
      name: "Is Not Empty"
    }



  ];
  lookupDataDropDown: LookUpElement[] = [
    {
      id: "Equal",
      name: "Is Equal To"
    },
    {
      id: "NotEqual",
      name: "Is Not Equal To"
    },
    {
      id: "Contains",
      name: "Contains"
    },
    {
      id: "StartsWith",
      name: "Starts With"
    },
    {
      id: "EndsWith",
      name: "Ends With"
    },
    {
      id: "IsEmpty",
      name: "Is Empty"
    },
    {
      id: "IsNotEmpty",
      name: "Is Not Empty"
    },
    {
      id: "IsAnyOf",
      name: "Is Any Of"
    },
    {
      id: "IsAllOf",
      name: "Is All Of"
    },

    {
      id: "IsNoneOf",
      name: "Is None Of"
    }



  ];
  filterClauseDropDown: LookUpElement[] = [
    {
      id: "AND",
      name: "AND",
    },
    {
      id: "OR",
      name: "OR",
    }
  ];

  numberDataDropDown: LookUpElement[] = [
    {
      id: "Equal",
      name: "Is Equal To"
    },
    {
      id: "NotEqual",
      name: "Is Not Equal To"
    },
    {
      id: "GreaterThan",
      name: "Greater Than"
    },
    {
      id: "LessThan",
      name: "Less Than"
    },
    {
      id: "GreaterThanOrEqual",
      name: "Greater Than Or Equal"
    },
    {
      id: "LessThanOrEqual",
      name: "Less Than Or Equal"
    },
    {
      id: "Between",
      name: "Between"
    },



  ];

  dateDataDropDown: LookUpElement[] = [
    {
      id: "Equal",
      name: "Is Equal To"
    },
    {
      id: "NotEqual",
      name: "Is Not Equal To"
    },
    {
      id: "GreaterThan",
      name: "Greater Than"
    },
    {
      id: "LessThan",
      name: "Less Than"
    },
    {
      id: "GreaterThanOrEqual",
      name: "Greater Than Or Equal"
    },
    {
      id: "LessThanOrEqual",
      name: "Less Than Or Equal"
    },
    {
      id: "Between",
      name: "Between"
    },
    {
      id: "IsAnyOf",
      name: "Is Any Of"
    },
    // {
    //   id: "IsAllOf",
    //   name: "Is All Of"
    // },

    // {
    //   id: "IsNoneOf",
    //   name: "Is None Of"
    // }


  ];
  numberDataLookupDropDown: LookUpElement[] = [
    {
      id: "Equal",
      name: "Is Equal To"
    },
    {
      id: "NotEqual",
      name: "Is Not Equal To"
    },
    {
      id: "GreaterThan",
      name: "Greater Than"
    },
    {
      id: "LessThan",
      name: "Less Than"
    },
    {
      id: "GreaterThanOrEqual",
      name: "Greater Than Or Equal"
    },
    {
      id: "LessThanOrEqual",
      name: "Less Than Or Equal"
    },
    {
      id: "Between",
      name: "Between"
    },
    {
      id: "IsAnyOf",
      name: "Is Any Of"
    },
    {
      id: "IsAllOf",
      name: "Is All Of"
    },

    {
      id: "IsNoneOf",
      name: "Is None Of"
    }


  ];
  allowSecondValue: string[] = []
  allowLookup: string[] = ['IsAnyOf', 'IsAllOf', 'IsNoneOf']
  allowSingleDate: string[] = ['Between', 'IsAllOf', 'IsAnyOf']
  joinValue: string = ""
  /**
   *
   */
  constructor(private modalService: BsModalService, private filterSharedService: FilterSharedService, private fb: FormBuilder, private dateAdapter: DateAdapter<Date>, private helperService: HelpersService,
    private translateService :TranslateService) {
    this.dateAdapter.setLocale('en-GB');
    this.filterDataSubject = filterSharedService.getsubjectFilterParameterOpenModal().pipe(takeUntil(this.destroy))
      .subscribe((sortByOpen: any) => {


        if (sortByOpen != null && sortByOpen == 'open') {

          this.showModal()
        }
      });

    this.filterDataSubject = filterSharedService.getsubjectFilterData().pipe(takeUntil(this.destroy))
      .subscribe((filterData: FilterData) => {
        if (filterData != null) {
          this.filterData = filterData

          this.coloumnsData = filterData?.ColumnsData;

          console.log(this.coloumnsData);

        }
      });
    this.filterDataSubject = filterSharedService.getsubjectFilterReset().pipe(takeUntil(this.destroy))
      .subscribe((sortByOpen: boolean) => {
        if (sortByOpen != null && sortByOpen) {

          this.resetFilter()
        }
      });

  }
  ngAfterViewInit(): void {

  }
  ngOnInit(): void {
    // console.log(this.filterForm.controls);
    this.allowSecondValue = ['Between']
    if (this.getFilterValues?.length == 0) {
      this.addNewFilterValue()
    }
    this.getValueChanges()
    this.setTranslation();

    // this.filterForm.addControl('FilterValues', []);
    // console.log(this.getFilterValues());
    // this.filterForm?.addControl("Filters", new FormArray([]));
  }

  setTranslation(){
    this.translateService.get(['FILTER_MENU.FROM','FILTER_MENU.VALUE','FILTER_MENU.IS_EQUAL_TO','FILTER_MENU.IS_NOT_EQUAL_TO','FILTER_MENU.CONTAINS',
    'FILTER_MENU.STARTS_WITH','FILTER_MENU.ENDS_WITH','FILTER_MENU.IS_EMPTY','FILTER_MENU.IS_NOT_EMPTY','FILTER_MENU.IS_ANY_OF', 'FILTER_MENU.IS_ALL_OF',
    'FILTER_MENU.IS_NONE_OF','FILTER_MENU.AND','FILTER_MENU.OR','FILTER_MENU.GREATER_THAN','FILTER_MENU.LESS_THAN','FILTER_MENU.GREATER_THAN_OR_EQUAL',
    'FILTER_MENU.LESS_THAN_OR_EQUAL','FILTER_MENU.BETWEEN'
     ]).subscribe(translations => {
      this.fromLabel = translations['FILTER_MENU.FROM'];
      this.valueLabel = translations['FILTER_MENU.VALUE'];
      this.stringDataDropDown[0].name = translations['FILTER_MENU.IS_EQUAL_TO'];
      this.stringDataDropDown[1].name = translations['FILTER_MENU.IS_NOT_EQUAL_TO'];
      this.stringDataDropDown[2].name = translations['FILTER_MENU.CONTAINS'];
      this.stringDataDropDown[3].name = translations['FILTER_MENU.STARTS_WITH'];
      this.stringDataDropDown[4].name = translations['FILTER_MENU.ENDS_WITH'];
      this.stringDataDropDown[5].name = translations['FILTER_MENU.IS_EMPTY'];
      this.stringDataDropDown[6].name = translations['FILTER_MENU.IS_NOT_EMPTY'];
      this.lookupDataDropDown[0].name = translations['FILTER_MENU.IS_EQUAL_TO'];
      this.lookupDataDropDown[1].name = translations['FILTER_MENU.IS_NOT_EQUAL_TO'];
      this.lookupDataDropDown[2].name = translations['FILTER_MENU.CONTAINS'];
      this.lookupDataDropDown[3].name = translations['FILTER_MENU.STARTS_WITH'];
      this.lookupDataDropDown[4].name = translations['FILTER_MENU.ENDS_WITH'];
      this.lookupDataDropDown[5].name = translations['FILTER_MENU.IS_EMPTY'];
      this.lookupDataDropDown[6].name = translations['FILTER_MENU.IS_NOT_EMPTY'];
      this.lookupDataDropDown[7].name = translations['FILTER_MENU.IS_ANY_OF'];
      this.lookupDataDropDown[8].name = translations['FILTER_MENU.IS_ALL_OF'];
      this.lookupDataDropDown[9].name = translations['FILTER_MENU.IS_NONE_OF'];
      this.filterClauseDropDown[0].name = translations['FILTER_MENU.AND'];
      this.filterClauseDropDown[1].name = translations['FILTER_MENU.OR'];
      this.numberDataDropDown[0].name = translations['FILTER_MENU.IS_EQUAL_TO'];
      this.numberDataDropDown[1].name = translations['FILTER_MENU.IS_NOT_EQUAL_TO'];
      this.numberDataDropDown[2].name = translations['FILTER_MENU.GREATER_THAN'];
      this.numberDataDropDown[3].name = translations['FILTER_MENU.LESS_THAN'];
      this.numberDataDropDown[4].name = translations['FILTER_MENU.GREATER_THAN_OR_EQUAL'];
      this.numberDataDropDown[5].name = translations['FILTER_MENU.LESS_THAN_OR_EQUAL'];
      this.numberDataDropDown[6].name = translations['FILTER_MENU.BETWEEN'];
      this.dateDataDropDown[0].name = translations['FILTER_MENU.IS_EQUAL_TO'];
      this.dateDataDropDown[1].name = translations['FILTER_MENU.IS_NOT_EQUAL_TO'];
      this.dateDataDropDown[2].name = translations['FILTER_MENU.GREATER_THAN'];
      this.dateDataDropDown[3].name = translations['FILTER_MENU.LESS_THAN'];
      this.dateDataDropDown[4].name = translations['FILTER_MENU.GREATER_THAN_OR_EQUAL'];
      this.dateDataDropDown[5].name = translations['FILTER_MENU.LESS_THAN_OR_EQUAL'];
      this.dateDataDropDown[6].name = translations['FILTER_MENU.BETWEEN'];
      this.dateDataDropDown[7].name = translations['FILTER_MENU.IS_ANY_OF'];
      this.numberDataLookupDropDown[0].name = translations['FILTER_MENU.IS_EQUAL_TO'];
      this.numberDataLookupDropDown[1].name = translations['FILTER_MENU.IS_NOT_EQUAL_TO'];
      this.numberDataLookupDropDown[2].name = translations['FILTER_MENU.GREATER_THAN'];
      this.numberDataLookupDropDown[3].name = translations['FILTER_MENU.LESS_THAN'];
      this.numberDataLookupDropDown[4].name = translations['FILTER_MENU.GREATER_THAN_OR_EQUAL'];
      this.numberDataLookupDropDown[5].name = translations['FILTER_MENU.LESS_THAN_OR_EQUAL'];
      this.numberDataLookupDropDown[6].name = translations['FILTER_MENU.BETWEEN'];
      this.numberDataLookupDropDown[7].name = translations['FILTER_MENU.IS_ANY_OF'];
      this.numberDataLookupDropDown[8].name = translations['FILTER_MENU.IS_ALL_OF'];
      this.numberDataLookupDropDown[9].name = translations['FILTER_MENU.IS_NONE_OF'];
   });
  }
  @Input() filterForm: FormGroup;



  private showModal() {

    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-right modal-lg'
    });
  }




  // Method to add a new FormArray dynamically
  addNewFiltersArray() {
    this.filterForm.addControl('Filters', this.fb.array([]));

    console.log(this.filterForm?.controls);




  }

  // Method to get the Filters FormArray


  get getFilterValues(): FormArray {

    return this.filterForm.controls["Filters"] as FormArray;
  }

  addNewFilterValue() {
    this.addNewFiltersArray()
    const filtersArray = this.getFilterValues;


    if (filtersArray) {
      filtersArray.push(this.newFilterValue());
    }

    if (this.getFilterValues?.controls && this.getFilterValues.controls?.length > 0) {

      var filterbyValues = this.getFilterValues.controls.map(element => element?.value?.FilterBy);

      console.log(filterbyValues);

      var filteredColsArray = this.coloumnsData.filter(x => !filterbyValues.includes(x?.data));
      this.getFilterValues.controls[filtersArray.length - 1].patchValue({
        ColoumnsArray: filteredColsArray
      })
    }
  }

  removeFilterValue(i: number) {
    this.getFilterValues.removeAt(i);
    if (i == 0) {
      this.addNewFilterValue()
      this.filterSharedService.setsubjectApplyFilter(true);
    }
  }

  // Helper method to create a new filter value (FormGroup)
  newFilterValue(): FormGroup {
    return this.fb.group({
      FilterBy: [''], // Make sure the FormControl name is 'FilterBy'
      Translate: [''],
      FilterClause: ['AND'], // Make sure the FormControl name is 'FilterBy'
      Condition: [''], // Make sure the FormControl name is 'FilterBy'
      ConditionText: [''], // Make sure the FormControl name is 'FilterBy'
      Value: [], // Make sure the FormControl name is 'FilterBy'
      SecondaryValue: [], // Make sure the FormControl name is 'FilterBy'
      ConditionArray: [this.stringDataDropDown],
      ColoumnsArray: [this.coloumnsData],
      FilterClauseArray: [this.filterClauseDropDown],
      DataType: ['STRING'],
      IsActive: true,
      Dates: this.fb.array([]),

      LookupArray: [[]],
      LookupValue: [[]]
      // Other FormControls if needed
    });
  }

  conditionChange(event, i) {
    this.getFilterValues.controls[i].get("ConditionArray").value.forEach(element => {
      if (event.value == element.id) {
        this.getFilterValues.controls[i].patchValue({
          ConditionText: element.name,
        })
      }
    });
    var data: any = this.coloumnsData.filter(res => res.data == this.getFilterValues.controls[i].get("FilterBy").value);
    // console.log(data?.lookup,"datadatadatadatadatadatadatadata");



    if (this.getFilterValues.controls[i].get("DataType").value == 'LOOKUP' || this.getFilterValues.controls[i].get("DataType").value == 'NUMBERLOOKUP') {
      this.getFilterValues.controls[i].patchValue({
        LookupArray: data[0]?.lookup,
      })

    } else {
      this.getFilterValues.controls[i].patchValue({
        LookupValue: [],
      })
    }

  }


  filterByChange(event, i) {


    var val = event.value

    var data = this.coloumnsData.filter(res => res.data == val);

    console.log(data);


    if (data.length > 0) {
      // const valueField =  this.getFilterValues.controls[i].get('Value');

      if (data[0]?.dataType == "NUMBER") {
        this.getFilterValues.controls[i].patchValue({
          Translate: data[0].translate,
          ConditionArray: this.numberDataDropDown,
          DataType: data[0]?.dataType,
          Condition: '',
          Value: ''
        })
        // valueField.setValidators([Validators.required, Validators.pattern('^[0-9]+$')]);
      } else if (data[0].dataType == "LOOKUP") {
        this.getFilterValues.controls[i].patchValue({
          Translate: data[0].translate,
          ConditionArray: this.lookupDataDropDown,
          DataType: data[0]?.dataType,
          Condition: '',
          Value: ''
        })
      }else if (data[0].dataType == "NUMBERLOOKUP") {
        this.getFilterValues.controls[i].patchValue({
          Translate: data[0].translate,
          ConditionArray: this.numberDataLookupDropDown,
          DataType: data[0]?.dataType,
          Condition: '',
          Value: ''
        })
      } else if (data[0].dataType == "DATE") {
        this.getFilterValues.controls[i].patchValue({
          Translate: data[0].translate,
          ConditionArray: this.dateDataDropDown,
          DataType: data[0]?.dataType,
          Condition: '',
          Value: '',
          SecondaryValue: ''
        })
      } else {
        this.getFilterValues.controls[i].patchValue({
          Translate: data[0].translate,
          ConditionArray: this.stringDataDropDown,
          DataType: data[0]?.dataType,
          Condition: '',
          Value: ''
        })
        // valueField.clearValidators();
      }

      this.conditionChange({ value: val }, i)

    }



  }

  onKeyUp(event: any, i: number) {
    const inputElement = event.target as HTMLInputElement;
    const currentValue = inputElement.value;
    var filterVal = this.getFilterValues.controls[i].get('FilterBy');

    console.log(filterVal);

    // Remove non-numeric characters
    const numericValue = currentValue.replace(/[^0-9]/g, '');


    var data = this.coloumnsData.filter(res => res.data == numericValue);

    if (data.length > 0) {
      if (data[0]?.dataType == "NUMBER") {
        inputElement.value = numericValue;

        this.getFilterValues.controls[i].get('Value').setValue(numericValue);
      }
    }


  }

  resetFilter() {
    this.getFilterValues.clear();
    this.addNewFilterValue()
    this.filterSharedService.setsubjectApplyFilter(true);
  }

  get dates(): FormArray {
    return this.filterForm.get('dates') as FormArray;
  }



  ngOnDestroy() {
    this.filterSharedService.setsubjectFilterParameterOpenModal(null)
    this.destroy.next(null);
    this.modalRef?.hide();
  }


  // addDateInital(event)
  addDate(event: any, i:number,isInitial:boolean) {

    const date = this.helperService.setDateFormatted(event.value);
    console.log("datedatedatedatedatedate", event);

    var clause = this.getFilterValues.controls[i].get('Dates')?.value;
    var datesArray = this.getFilterValues.controls[i].get('Dates') as FormArray;
    console.log(this.getFilterValues.controls[i].get('Condition')?.value, !this.allowSingleDate.includes(this.getFilterValues.controls[i].get('Condition')?.value));

    if (isInitial) {
      this.getFilterValues.controls[i].get("Value").patchValue(date)
    }

    if (!this.allowSingleDate.includes(this.getFilterValues.controls[i].get('Condition')?.value)) {
      const datesArray = this.getFilterValues.controls[i].get('Dates') as FormArray;

      // Check if there are more than 1 elements in the FormArray
      if (datesArray.length > 1) {
        // Remove all elements except the first one (at index 0)
        while (datesArray.length > 1) {
          datesArray.removeAt(1);
        }
      }
      this.getFilterValues.controls[i].patchValue({
        Value: date.toString()
      })

      // this.getFilterValues.controls[i].get('Dates')?.value.push(this.fb.control(date));
    } else {

      if (!this.getFilterValues.controls[i].get('Dates')?.value.includes(date)) {


        this.getFilterValues.controls[i].get('Dates')?.value.push(this.fb.control(date));


      }
      if (this.getFilterValues.controls[i].get('Condition').value == 'IsAnyOf' && this.getFilterValues.controls[i].get('DataType').value == 'DATE') {
        this.getFilterValues.controls[i].patchValue({
          LookupValue: this.getFilterValues.controls[i].get('Dates')?.value.map(x=>x.value)
        })
      }
    }
 


  }

  removeDate(index: number, i) {
    console.log(this.getFilterValues.controls[i].get('Dates').value, "DatesDatesDatesDates",index);

    const datesArray = this.getFilterValues.controls[i].get('Dates') as FormArray;
    datesArray.removeAt(0);

    console.log(datesArray);
    
  }
  getValueChanges() {



    this.filterForm.controls.Filters?.valueChanges.subscribe((searchformValues) => {


      this.filterOutputArray = [];

      var filters: FilterFilterFormData[] = searchformValues;

      filters.forEach(filterVal => {


        if (filterVal.IsActive && filterVal.FilterBy != "" && filterVal.Condition != "") {

          this.filterOutputArray.push({
            condition: filterVal.Condition,
            filterBy: filterVal.FilterBy,
            filterClause: filterVal.FilterClause,
            value: filterVal.Value,
            dataType: filterVal.DataType,
            secondaryValue: filterVal.SecondaryValue,
            lookupValueList: filterVal.LookupValue
          })
        }
        // this.filterOutputArray=[]


      });

      this.filterForm.patchValue({
        "FilterValues": this.filterOutputArray
      })



    });
  }

  getlookupvalue(i) {
    var lookupValue = this.getFilterValues?.controls[i]?.get("LookupValue").value;
    var lookupArray = this.getFilterValues?.controls[i]?.get("LookupArray").value;

    this.joinValue = lookupArray.filter(c => lookupValue.includes(c.id))
      .map(c => c.name).join(', ');


    console.log(this.joinValue, lookupArray.filter(c => lookupValue.includes(c)), lookupArray, lookupValue, "joinValue");


  }
  
  applyFilter() {
    console.log("param trigger");
    
    this.filterSharedService.setsubjectApplyFilter(true);
  }
}
