import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VehicleAssignmentSharedService {
  private subjectOpenVehicleAssignmentAddModal =new BehaviorSubject(null);
  private subjectOpenVehicleAssignmentViewModal =new BehaviorSubject(null);
  private subjectOpenVehicleAssignmentEditModal =new BehaviorSubject(null);
  private subjectCategoryTypeChanged =new BehaviorSubject(null);
  private subjectVehicleAssignmentCreated =new BehaviorSubject(null);
  private subjectVehicleAssignmentEdited =new BehaviorSubject(null);
  private subjectCalenderDataChanged =new BehaviorSubject(null);
  private subjectOpenRequestListModal =new BehaviorSubject(null);

  setsubjectOpenVehicleAssignmentAddModal(string: string) {
    this.subjectOpenVehicleAssignmentAddModal.next(string);
  }
  getsubjectOpenVehicleAssignmentAddModal(): Observable<any> {
    return this.subjectOpenVehicleAssignmentAddModal.asObservable();
  }
  setsubjectOpenVehicleAssignmentViewModal(string: string) {
    this.subjectOpenVehicleAssignmentViewModal.next(string);
  }
  getsubjectOpenVehicleAssignmentViewModal(): Observable<any> {
    return this.subjectOpenVehicleAssignmentViewModal.asObservable();
  }
  setsubjectOpenVehicleAssignmentEditModal(id: string) {
    this.subjectOpenVehicleAssignmentEditModal.next(id);

  }
  getsubjectOpenVehicleAssignmentEditModal(): Observable<any> {
    return this.subjectOpenVehicleAssignmentEditModal.asObservable();
  }
  setsubjectCategoryTypeChanged(type :any){
    this.subjectCategoryTypeChanged.next(type)
  }
  getsubjectCategoryTypeChanged() :  Observable<string>{
   return this.subjectCategoryTypeChanged.asObservable()
  }
  setsubjectVehicleAssignmentCreated(type :any){
    this.subjectVehicleAssignmentCreated.next(type)
  }
  getsubjectVehicleAssignmentCreated() :  Observable<string>{
   return this.subjectVehicleAssignmentCreated.asObservable()
  }
  setsubjectVehicleAssignmentEdited(type :any){
    this.subjectVehicleAssignmentEdited.next(type)
  }
  getsubjectVehicleAssignmentEdited() :  Observable<string>{
   return this.subjectVehicleAssignmentEdited.asObservable()
  }
  setsubjectCalenderDataChanged(type :boolean){
    this.subjectCalenderDataChanged.next(type)
  }
  getsubjectCalenderDataChanged() :  Observable<boolean>{
   return this.subjectCalenderDataChanged.asObservable()
  }
  setSubjectOpenRequestListModal(id: any) {
    this.subjectOpenRequestListModal.next(id);
  }
  getSubjectOpenRequestListModal(): Observable<any> {
    return this.subjectOpenRequestListModal.asObservable();
  }

  
}
